/*------------------------------------*\
    Page Template: Sponsor
    sponsor.php
    .page-template-sponsor
\*------------------------------------*/

.page-template-sponsor {



    .box--page {

        .content {

            .title {

                font-size: 3.2rem;
                line-height: 1.25;

            }// .title

            .text {}// .text

        }// .content

        .sponsor {

            margin-top: $gutter;
            background-color: var(--c-white);

            & > .title {

                color: var(--c-white);
                background-color: var(--c-brand-panel);
                padding: 2rem;
                font-size: 2.4rem;
                font-weight: var(--f-normal);
                line-height: 1.2;

            }// .title

            .items {

                padding: $gutter;

                display: flex;
                flex-wrap: wrap;
                justify-content: space-evenly;
                gap: $gutter*2 $gutter;

                @media #{$media-s} {
                    padding: $gutter*.5;
                    gap: $gutter;
                }

                .item {

                    width: 20rem;
                    text-align: center;

                    .image {

                        display: block;
                        width: 100%;
                        height: 10rem;
                        margin: auto;

                        .img {

                            display: block;
                            width: 100%;
                            height: 100%;
                            object-fit: contain;

                        }// .img

                    }// .image

                    & > .title {

                        margin-top: $gutter*.5;
                        font-weight: var(--f-bold);

                    }// .title

                    & > .link {

                        font-size: 1.4rem;

                    }// .link

                    .info {// details

                        margin-top: $gutter*.5;

                        .info-title {// summary

                            margin: auto;
                            border-radius: 50%;
                            width: 3rem;
                            height: 3rem;
                            cursor: pointer;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            color: var(--c-white);
                            background-color: var(--c-brand);

                            &:hover,
                            &:focus-within {
                                background-color: var(--c-brand-dark);
                            }

                            &::before { content: '+'; }

                        }// .info-title

                        .info-content {

                            margin-top: $gutter*.5;

                            font-size: 1.4rem;

                            .text + .link { margin-top: $gutter*.25; }

                            .link {

                                text-decoration: underline;

                            }// .link

                        }// info-content

                        &[open] {

                            .info-title { content: '-'; }

                        }

                    }// .info

                }// .item

            }// .items

        }// .sponsor

    }// .box--page



}// .page-template-sponsor
