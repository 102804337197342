/*------------------------------------*\
    Footer
   #footer.footer
\*------------------------------------*/

.footer {

    margin-top: $gutter;
    position: relative;
    padding: 2rem;

    font-family: var(--f-title);


    .footer_instagram {

        color: var(--c-brand);
        // background-color: var(--c-white);

        padding: 2rem;

        .title {

            margin: auto;
            max-width: $w-media-u;

            color: var(--c-brand);
            display: flex;
            align-items: center;
            gap: 1rem;

            font-size: 2.6rem;
            font-weight: var(--f--normal);
            line-height: 1.2;
            margin-bottom: $gutter;

            &::before {
                content: '';
                display: inline-block;
                width: 6.5rem;
                height: .6rem;
                background-color: currentColor;
            }

        }// .title

        // .items {
        //
        //     margin: auto;
        //     max-width: $w-media-u;
        //
        // }// .items

    }// .footer_instagram



    .footer_sponsor {

        color: var(--c-brand);
        background-color: var(--c-white);

        padding: 2rem;

        .title {

            margin: auto;
            max-width: $w-media-u;

            color: var(--c-brand);
            display: flex;
            align-items: center;
            gap: 1rem;

            font-size: 2.6rem;
            font-weight: var(--f--normal);
            line-height: 1.2;
            margin-bottom: $gutter;

            &::before {
                content: '';
                display: inline-block;
                width: 6.5rem;
                height: .6rem;
                background-color: currentColor;
            }

        }// .title

        .items {

            margin: auto;
            max-width: $w-media-u;

            margin-top: $gutter;
            margin-bottom: $gutter;

            display: flex;
            justify-content: space-evenly;
            flex-wrap: wrap;
            gap: 4rem;

            .slick-list,
            .slick-track,
            .slick-slide,
            .item {
                width: 100%;
                height: 100%;
            }
            /*.slick-list {
                margin: 0 -8rem;
                @media #{$media-m} { margin: 0 -6rem; }
            }
            .slick-slide {
                margin: 0 8rem;
                @media #{$media-m} { margin: 0 -6rem; }
            }*/

            .slick-dots {

                margin: auto;
                margin-top: 0;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;

                li {

                    position: relative;
                    display: inline-flex;
                    align-items: center;
                    width: 2.4rem;
                    height: 2rem;

                    cursor: pointer;
                    // background-color: var(--c-grey-normal);

                    &:not(:first-of-type) { margin-left: 2rem; }

                    // &.slick-active { background-color: var(--c-brand); }

                    &::before {
                        content: '';
                        display: block;
                        width: 100%;
                        height: .6rem;
                        background-color: var(--c-grey-normal);
                    }

                    &.slick-active::before { background-color: var(--c-brand); }

                }

                button {
                    display: none;
                }

            }// .slick-dots

            .item {

                //width: 25rem;

                .image {

                    display: block;
                    width: 20rem;
                    height: 10rem;
                    margin: auto;

                    .img {

                        display: block;
                        width: 100%;
                        height: 100%;
                        object-fit: contain;

                    }// .img

                }// .image

            }// .item

        }// .items

    }// .footer_sponsor



    .footer__getintouch {

        color: var(--c-white);
        background-color: var(--c-grey-dark);

        padding: 4rem 2rem;

        .inner {

            margin: auto;
            max-width: $w-media-u;

            display: grid;
            grid-gap: $gutter;
            grid-template-columns: 1fr 1fr;

            @media #{$media-m} { grid-template-columns: repeat(1, 1fr); }

        }// .inner

        .cta {

            .title {

                margin: auto;

                color: var(--c-white);
                display: flex;
                align-items: center;
                gap: 1rem;

                font-size: 2.4rem;
                line-height: 1.2;

                &::before {
                    content: '';
                    display: inline-block;
                    width: 6.5rem;
                    height: .6rem;
                    background-color: currentColor;
                }

            }// .title

            .title + .title2 { margin-top: $gutter*.25; }

            .title2 {

                margin-left: calc(6.5rem + 1rem);
                color: var(--c-brand-accent);
                font-size: 1.8rem;
                line-height: 1.2;

            }// .title2

            .link {

                display: inline-block;
                margin-top: $gutter*.5;
                margin-left: calc(6.5rem + 1rem);

                color: var(--c-brand-accent);
                background-color: var(--c-black);

                padding: 1.5rem 3rem;
                text-transform: uppercase;
                font-size: 1.4rem;
                line-height: 1.1;

                &:hover,
                &:focus-within {

                    color: var(--c-black);
                    background-color: var(--c-brand-accent);

                }

            }// .link

        }// .cta

        .social {

            border-left: .1rem solid var(--c-grey-light);
            display: flex;
            flex-direction: column;
            align-items: center;

            @media #{$media-m} {
                border-left: none;
                border-top: .1rem solid var(--c-grey-light);
                padding-top: 2rem;
            }

            .title {

                color: var(--c-brand-accent);
                font-size: 2.2rem;

            }// .title

            .items {

                margin: auto;
                margin-top: 1rem;
                display: flex;
                flex-wrap: wrap;
                gap: 2rem 1rem;

                @media #{$media-sm} {
                    justify-content: center;
                }

            }// .items

            .link {

                color: var(--c-white);
                display: block;
                width: 5rem;
                height: 5rem;

                svg {

                    display: block;
                    width: 100%;
                    height: 100%;
                    fill: currentColor;

                }// svg

                &:hover,
                &:focus-within {

                    color: var(--c-brand-accent);

                }

            }// .link

        }// .social

    }// .footer__getintouch



    .footer__content {

        padding: 2rem 0;

        display: flex;
        gap: $gutter;

        @media #{$media-s} { flex-direction: column; }

        .brand {

            align-self: flex-start;
            width: 10rem;

            .link,
            svg {
                display: block;
                width: 100%;
                height: 100%;
            }

        }// .brand

        .footer__info {

            .title {

                font-size: 2.6rem;
                line-height: 1.2;

            }// .title

            .info {

                margin-top: $gutter*.5;

            }// .info

            .label {

                display: flex;
                align-items: center;

                &::before {
                    flex-shrink: 0;
                    align-self: flex-start;
                    content: '';
                    display: block;
                    width: 2.5rem;
                    height: 2.5rem;
                    margin-top: .25rem;
                    margin-right: 2rem;
                    background-position: center;
                    background-size: contain;
                    background-repeat: no-repeat;
                }

            }// .label

            .map {

                margin-top: 1rem;
                display: flex;
                align-items: center;
                margin-left: 5rem;

                &::before {
                    content: '';
                    display: block;
                    width: 2rem;
                    height: 2rem;
                    margin-right: 1rem;
                    background-position: center;
                    background-size: contain;
                    background-repeat: no-repeat;
                }

            }// .map

            .info--phone .label::before { background-image: url(../images/icon-phone.svg); }
            .info--email .label::before { background-image: url(../images/icon-mail.svg); }
            .info--address .label::before { background-image: url(../images/icon-address.svg); }
            .map::before { background-image: url(../images/icon-external.svg); }

        }// .footer__info

    }// .footer__content



    .footer__legal {

        padding: 2rem;
        margin: 0 -2rem -2rem -2rem;
        font-size: 1.4rem;

        color: var(--c-white);
        background-color: var(--c-brand-dark);

        display: flex;
        flex-wrap: wrap;
        gap: $gutter;

        @media #{$media-sm} { flex-direction: column; }

        .footer__copy {}// .footer__copy

        .footer__policy {

            margin-left: auto;
            @media #{$media-sm} { margin-left: initial; }

            .items {

                display: flex;
                justify-content: center;
                gap: $gutter*.5;

                @media #{$media-sm} { flex-direction: column; }

                .item {

                    font-size: 1.4rem;
                    text-transform: uppercase;
                    display: flex;
                    align-items: center;

                    &::before {
                        content: '';
                        display: block;
                        width: .1rem;
                        height: 1.6rem;
                        background-color: var(--c-grey-light);
                        margin-right: $gutter*.5;
                    }

                    &:first-of-type::before {

                        content: none;
                        @media #{$media-sm} { content: ''; }

                    }


                    .link {

                        &:hover,
                        &:focus-within {

                            color: var(--c-brand-accent);

                        }

                    }// .link

                }// .item

            }// .items

        }// .footer__policy

    }// .footer__legal



}// .footer
