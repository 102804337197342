@media screen and (prefers-reduced-motion: no-preference) {

	html,
	body {
		scroll-behavior: smooth;
	}

}

html {
	font-size: 62.5%;
}

	body {

		color: var(--c-fg);
		background-color: var(--c-bg);

		font-family: var(--f-text);
		font-weight: var(--f-text-normal);
		font-size: 1.6rem;
		line-height: 1.8;
        -webkit-text-size-adjust: 100%;

		&.main__nav-opened {
			overflow: hidden;
			height: 100%;

			/* Hide scrollbar for Chrome, Safari and Opera */
			&::-webkit-scrollbar {
				display: none;
			}

			-ms-overflow-style: none;  /* IE and Edge */
			scrollbar-width: none;  /* Firefox */

			 // position: fixed;

		}// &.main__nav-opened

	}
	@-moz-document url-prefix() {
    	html.main__nav-opened,body.main__nav-opened{ overflow: hidden; }
	}

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			font-family: var(--f-title);
		}

		p {}

		strong {
			font-weight: var(--f-bold);
		}

		a {
			text-decoration: none;
			color: currentColor;

			&:hover {}

		}










/*------------------------------------*\
   general
\*------------------------------------*/

::selection {
	background-color: var(--c-brand);
	color: var(--c-white);
}

:focus { outline: .1rem dashed var(--c-brand-accent); outline-offset: .2rem; }

.none { display: none; }

.hidden { visibility: hidden; }

.uppercase { text-transform: uppercase; }

.anchor {
    display: block;
    position: relative;
    top: calc(var(--h-header) * -1);
    visibility: hidden;
}

.sr-only {
	border: 0 !important;
	clip: rect(1px, 1px, 1px, 1px) !important;
	-webkit-clip-path: inset(50%) !important;
	clip-path: inset(50%) !important;
	height: 1px !important;
	overflow: hidden !important;
	padding: 0 !important;
	position: absolute !important;
	width: 1px !important;
	white-space: nowrap !important;
}





/*------------------------------------*\
   Text
\*------------------------------------*/

.text {

	a {
		color: currentColor;
	}

	ul,
	ol {
		margin: $gutter * .5;
	}

	ol { list-style-type: decimal; }
	ul { list-style-type: disc; }
	li {}

	p + p {
		margin-top: $gutter * .5;
	}

	strong { font-weight: var(--f-bold); }
	em { font-style: italic; }

}// .text

.text--wysiwyg {

	h1 { font-size: 3.8rem; line-height: 1.25; }
	h2 { font-size: 3.2rem; line-height: 1.25; }
	h3 { font-size: 2.6rem; line-height: 1.25; }
	h4 { font-size: 2.2rem; line-height: 1.25; }
	h5 { font-size: 2.0rem; line-height: 1.25; }
	h6 { font-size: 1.8rem; line-height: 1.25; }

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin-bottom: .65em;
	}

	p {
		font-size: 1.8rem;
		line-height: 1.5;
	}

	p + h1,
	p + h2,
	p + h3,
	p + h4,
	p + h5,
	p + h6 { margin-top: $gutter; }

	a {
		color: var(--c-brand);
		text-decoration: underline;
	}
	a:hover,
	a:focus-within {
		background-color: var(--c-brand-accent);
	}

	.gallery {

        margin-top: $gutter;
		margin-bottom: $gutter;
		margin-left: -1rem;
		margin-right: -1rem;
        display: flex;
		flex-wrap: wrap;
		// gap: 2rem;

        .slick-list,
        .slick-track,
        .slick-slide {
            width: 100%;
            height: 100%;
        }

        .slick-dots {

            width: 100%;
            margin-top: 0;
            height: 4rem;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
			gap: 2rem;
			list-style: none;
			margin: 0;

            li {

                position: relative;
                display: inline-block;
                width: 1.8rem;
                height: 1.8rem;
                cursor: pointer;
                background-color: var(--c-grey-dark);

                &.slick-active {
                    background-color: var(--c-brand-accent);
                }

            }

            button {
                display: none;
            }

        }// .slick-dots

        .gallery-item  {

			width: calc(33.3333% - 2rem);
			margin: 1rem;

			.gallery-icon {

				a {}

				img {
					display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
				}

			}// .gallery-icon

        }// .gallery-item

	}// .gallery

}// .text--wysiwyg
