// Lato
@font-face {
    font-family: 'Lato';
    src: url('../fonts/Lato-Regular.woff2') format('woff2'),
        url('../fonts/Lato-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;// 400
    font-display: swap;
}
@font-face {
    font-family: 'Lato';
    src: url('../fonts/Lato-Bold.woff2') format('woff2'),
        url('../fonts/Lato-Bold.woff') format('woff');
    font-weight: bold;// 700
    font-style: normal;
    font-display: swap;
}





// Open Sans
@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/OpenSans-Regular.woff2') format('woff2'),
        url('../fonts/OpenSans-Regular.woff') format('woff');
    font-weight: normal;// 400
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/OpenSans-Bold.woff2') format('woff2'),
        url('../fonts/OpenSans-Bold.woff') format('woff');
    font-weight: bold;// 700
    font-style: normal;
    font-display: swap;
}
