/* Slider */
.slick-slider
{
    position: relative;

    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
        touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list
{
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
}
.slick-list:focus
{
    outline: none;
}
.slick-list.dragging
{
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list
{
    -webkit-transform: translate3d(0, 0, 0);
       -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
         -o-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}

.slick-track
{
    position: relative;
    top: 0;
    left: 0;

    display: block;
    margin-left: auto;
    margin-right: auto;
}
.slick-track:before,
.slick-track:after
{
    display: table;

    content: '';
}
.slick-track:after
{
    clear: both;
}
.slick-loading .slick-track
{
    visibility: hidden;
}

.slick-slide
{
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
}
[dir='rtl'] .slick-slide
{
    float: right;
}
.slick-slide img
{
    display: block;
}
.slick-slide.slick-loading img
{
    display: none;
}
.slick-slide.dragging img
{
    pointer-events: none;
}
.slick-initialized .slick-slide
{
    display: block;
}
.slick-loading .slick-slide
{
    visibility: hidden;
}
.slick-vertical .slick-slide
{
    display: block;

    height: auto;

    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}

/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8; }

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden; }

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box; }

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle; }

.mfp-align-top .mfp-container:before {
  display: none; }

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045; }

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto; }

.mfp-ajax-cur {
  cursor: progress; }

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out; }

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in; }

.mfp-auto-cursor .mfp-content {
  cursor: auto; }

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none; }

.mfp-loading.mfp-figure {
  display: none; }

.mfp-hide {
  display: none !important; }

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044; }
  .mfp-preloader a {
    color: #CCC; }
    .mfp-preloader a:hover {
      color: #FFF; }

.mfp-s-ready .mfp-preloader {
  display: none; }

.mfp-s-error .mfp-content {
  display: none; }

button.mfp-close,
button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation; }

button::-moz-focus-inner {
  padding: 0;
  border: 0; }

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace; }
  .mfp-close:hover,
  .mfp-close:focus {
    opacity: 1; }
  .mfp-close:active {
    top: 1px; }

.mfp-close-btn-in .mfp-close {
  color: #333; }

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%; }

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap; }

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent; }
  .mfp-arrow:active {
    margin-top: -54px; }
  .mfp-arrow:hover,
  .mfp-arrow:focus {
    opacity: 1; }
  .mfp-arrow:before,
  .mfp-arrow:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 35px;
    margin-left: 35px;
    border: medium inset transparent; }
  .mfp-arrow:after {
    border-top-width: 13px;
    border-bottom-width: 13px;
    top: 8px; }
  .mfp-arrow:before {
    border-top-width: 21px;
    border-bottom-width: 21px;
    opacity: 0.7; }

.mfp-arrow-left {
  left: 0; }
  .mfp-arrow-left:after {
    border-right: 17px solid #FFF;
    margin-left: 31px; }
  .mfp-arrow-left:before {
    margin-left: 25px;
    border-right: 27px solid #3F3F3F; }

.mfp-arrow-right {
  right: 0; }
  .mfp-arrow-right:after {
    border-left: 17px solid #FFF;
    margin-left: 39px; }
  .mfp-arrow-right:before {
    border-left: 27px solid #3F3F3F; }

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px; }
  .mfp-iframe-holder .mfp-content {
    line-height: 0;
    width: 100%;
    max-width: 900px; }
  .mfp-iframe-holder .mfp-close {
    top: -40px; }

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%; }
  .mfp-iframe-scaler iframe {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #000; }

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto; }

/* The shadow behind the image */
.mfp-figure {
  line-height: 0; }
  .mfp-figure:after {
    content: '';
    position: absolute;
    left: 0;
    top: 40px;
    bottom: 40px;
    display: block;
    right: 0;
    width: auto;
    height: auto;
    z-index: -1;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #444; }
  .mfp-figure small {
    color: #BDBDBD;
    display: block;
    font-size: 12px;
    line-height: 14px; }
  .mfp-figure figure {
    margin: 0; }

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto; }

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px; }

.mfp-image-holder .mfp-content {
  max-width: 100%; }

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer; }

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0; }
  .mfp-img-mobile img.mfp-img {
    padding: 0; }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0; }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px; }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box; }
    .mfp-img-mobile .mfp-bottom-bar:empty {
      padding: 0; }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px; }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0; } }

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75); }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0; }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%; }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px; } }

@charset "UTF-8";
/* Used colors

#035487     -> hsl(203, 96%, 27%)
#002a45     -> hsl(203, 100%, 14%)

#f2cb05     -> hsl(50, 96%, 48%)

#7b8c7e     -> hsl(131, 7%, 52%)
#9dbf21     -> hsl(73, 71%, 44%)

// greyscale

#000000     -> hsl(0, 0%, 0%)

#707070     -> hsl(0, 0%, 44%)
#cccccc     -> hsl(0, 0%, 80%)
#f2f2f2     -> hsl(0, 0%, 95%)

#ffffff     -> hsl(0, 0%, 100%)

*/
:root {
  --c-brand:              #035487;
  --c-brand-dark:         #002a45;
  --c-brand-accent:       #f2cb05;
  --c-brand-panel:        #7b8c7e;
  --c-black:              #000;
  --c-grey-dark:          #707070;
  --c-grey-normal:        #ccc;
  --c-grey-light:         #f2f2f2;
  --c-white:              #fff;
  --c-white-hsl:          0, 0%, 100%;
  --c-bg:                 var(--c-grey-light);
  --c-fg:                 var(--c-black);
  --c-link:               var(--c-brand-accent);
  --c-link-hover:         var(--c-brand);
  --c-error:              #c00;
  --c-modal:              var(--c-black);
  --h-header:             12rem;
  --gutter:               4rem;
  --f-title:              "Lato", sans-serif;
  --f-text:               "Open Sans", sans-serif;
  --f-normal:            normal;
  --f-bold:              bold; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/*
	HTML5 Reset :: style.css
	----------------------------------------------------------
	We have learned much from/been inspired by/taken code where offered from:

	Eric Meyer					:: http://meyerweb.com
	HTML5 Doctor				:: http://html5doctor.com
	and the HTML5 Boilerplate	:: http://html5boilerplate.com

-------------------------------------------------------------------------------*/
/* Let's default this puppy out
-------------------------------------------------------------------------------*/
html, body, body div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, figure, footer, header, menu, nav, section, time, mark, audio, video, details, summary {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-weight: inherit;
  vertical-align: baseline;
  background: transparent; }

article, aside, figure, footer, header, nav, section, details, summary {
  display: block; }

/* Handle box-sizing while better addressing child elements:
   http://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ */
html {
  box-sizing: border-box; }

*,
*::before,
*::after {
  box-sizing: inherit; }

/* consider resetting the default cursor: https://gist.github.com/murtaugh/5247154 */
/* Responsive images and other embedded objects
   Note: keeping IMG here will cause problems if you're using foreground images as sprites.
   If this default setting for images is causing issues, you might want to replace it with a .responsive class instead. */
img,
object,
embed {
  max-width: 100%; }

/* force a vertical scrollbar to prevent a jumpy page */
html {
  overflow-y: scroll; }

/* we use a lot of ULs that aren't bulleted.
	don't forget to restore the bullets within content. */
ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

del {
  text-decoration: line-through; }

abbr[title], dfn[title] {
  border-bottom: 1px dotted #000;
  cursor: help; }

/* tables still need cellspacing="0" in the markup */
table {
  border-collapse: collapse;
  border-spacing: 0; }

th {
  font-weight: bold;
  vertical-align: bottom; }

td {
  font-weight: normal;
  vertical-align: top; }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0; }

input, select {
  vertical-align: middle; }

pre {
  white-space: pre;
  /* CSS2 */
  white-space: pre-wrap;
  /* CSS 2.1 */
  white-space: pre-line;
  /* CSS 3 (and 2.1 as well, actually) */
  word-wrap: break-word;
  /* IE */ }

input[type="radio"] {
  vertical-align: text-bottom; }

input[type="checkbox"] {
  vertical-align: bottom; }

.ie7 input[type="checkbox"] {
  vertical-align: baseline; }

.ie6 input {
  vertical-align: text-bottom; }

select, input, textarea {
  font: 99% sans-serif; }

table {
  font-size: inherit;
  font: 100%; }

small {
  font-size: 85%; }

strong {
  font-weight: bold; }

td, td img {
  vertical-align: top; }

/* Make sure sup and sub don't mess with your line-heights http://gist.github.com/413930 */
sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

/* standardize any monospaced elements */
pre, code, kbd, samp {
  font-family: monospace, sans-serif; }

/* hand cursor on clickable elements */
.clickable,
label,
input[type=button],
input[type=submit],
input[type=file],
button {
  cursor: pointer; }

/* Webkit browsers add a 2px margin outside the chrome of form elements */
button, input, select, textarea {
  margin: 0; }

/* make buttons play nice in IE */
button,
input[type=button] {
  width: auto;
  overflow: visible; }

/* scale images in IE7 more attractively */
.ie7 img {
  -ms-interpolation-mode: bicubic; }

/* prevent BG image flicker upon hover
   (commented out as usage is rare, and the filter syntax messes with some pre-processors)
.ie6 html {filter: expression(document.execCommand("BackgroundImageCache", false, true));}
*/
/* let's clear some floats */
@font-face {
  font-family: 'Lato';
  src: url("../fonts/Lato-Regular.woff2") format("woff2"), url("../fonts/Lato-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Lato';
  src: url("../fonts/Lato-Bold.woff2") format("woff2"), url("../fonts/Lato-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Open Sans';
  src: url("../fonts/OpenSans-Regular.woff2") format("woff2"), url("../fonts/OpenSans-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Open Sans';
  src: url("../fonts/OpenSans-Bold.woff2") format("woff2"), url("../fonts/OpenSans-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap; }

@media screen and (prefers-reduced-motion: no-preference) {
  html,
  body {
    scroll-behavior: smooth; } }

html {
  font-size: 62.5%; }

body {
  color: var(--c-fg);
  background-color: var(--c-bg);
  font-family: var(--f-text);
  font-weight: var(--f-text-normal);
  font-size: 1.6rem;
  line-height: 1.8;
  -webkit-text-size-adjust: 100%; }
  body.main__nav-opened {
    overflow: hidden;
    height: 100%;
    /* Hide scrollbar for Chrome, Safari and Opera */
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */ }
    body.main__nav-opened::-webkit-scrollbar {
      display: none; }

@-moz-document url-prefix() {
  html.main__nav-opened, body.main__nav-opened {
    overflow: hidden; } }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--f-title); }

strong {
  font-weight: var(--f-bold); }

a {
  text-decoration: none;
  color: currentColor; }

/*------------------------------------*\
   general
\*------------------------------------*/
::selection {
  background-color: var(--c-brand);
  color: var(--c-white); }

:focus {
  outline: 0.1rem dashed var(--c-brand-accent);
  outline-offset: .2rem; }

.none {
  display: none; }

.hidden {
  visibility: hidden; }

.uppercase {
  text-transform: uppercase; }

.anchor {
  display: block;
  position: relative;
  top: calc(var(--h-header) * -1);
  visibility: hidden; }

.sr-only {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important; }

/*------------------------------------*\
   Text
\*------------------------------------*/
.text a {
  color: currentColor; }

.text ul,
.text ol {
  margin: 2rem; }

.text ol {
  list-style-type: decimal; }

.text ul {
  list-style-type: disc; }

.text p + p {
  margin-top: 2rem; }

.text strong {
  font-weight: var(--f-bold); }

.text em {
  font-style: italic; }

.text--wysiwyg h1 {
  font-size: 3.8rem;
  line-height: 1.25; }

.text--wysiwyg h2 {
  font-size: 3.2rem;
  line-height: 1.25; }

.text--wysiwyg h3 {
  font-size: 2.6rem;
  line-height: 1.25; }

.text--wysiwyg h4 {
  font-size: 2.2rem;
  line-height: 1.25; }

.text--wysiwyg h5 {
  font-size: 2.0rem;
  line-height: 1.25; }

.text--wysiwyg h6 {
  font-size: 1.8rem;
  line-height: 1.25; }

.text--wysiwyg h1,
.text--wysiwyg h2,
.text--wysiwyg h3,
.text--wysiwyg h4,
.text--wysiwyg h5,
.text--wysiwyg h6 {
  margin-bottom: .65em; }

.text--wysiwyg p {
  font-size: 1.8rem;
  line-height: 1.5; }

.text--wysiwyg p + h1,
.text--wysiwyg p + h2,
.text--wysiwyg p + h3,
.text--wysiwyg p + h4,
.text--wysiwyg p + h5,
.text--wysiwyg p + h6 {
  margin-top: 4rem; }

.text--wysiwyg a {
  color: var(--c-brand);
  text-decoration: underline; }

.text--wysiwyg a:hover,
.text--wysiwyg a:focus-within {
  background-color: var(--c-brand-accent); }

.text--wysiwyg .gallery {
  margin-top: 4rem;
  margin-bottom: 4rem;
  margin-left: -1rem;
  margin-right: -1rem;
  display: flex;
  flex-wrap: wrap; }
  .text--wysiwyg .gallery .slick-list,
  .text--wysiwyg .gallery .slick-track,
  .text--wysiwyg .gallery .slick-slide {
    width: 100%;
    height: 100%; }
  .text--wysiwyg .gallery .slick-dots {
    width: 100%;
    margin-top: 0;
    height: 4rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    list-style: none;
    margin: 0; }
    .text--wysiwyg .gallery .slick-dots li {
      position: relative;
      display: inline-block;
      width: 1.8rem;
      height: 1.8rem;
      cursor: pointer;
      background-color: var(--c-grey-dark); }
      .text--wysiwyg .gallery .slick-dots li.slick-active {
        background-color: var(--c-brand-accent); }
    .text--wysiwyg .gallery .slick-dots button {
      display: none; }
  .text--wysiwyg .gallery .gallery-item {
    width: calc(33.3333% - 2rem);
    margin: 1rem; }
    .text--wysiwyg .gallery .gallery-item .gallery-icon img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover; }

/*------------------------------------*\
   layout
\*------------------------------------*/
html,
body {
  height: 100%; }

body {
  min-height: 100vh;
  display: flex;
  flex-direction: column; }

#header,
#footer {
  flex: none; }

#main {
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  position: relative;
  z-index: 1; }

.container {
  margin-right: auto;
  margin-left: auto;
  padding-right: 4rem;
  padding-left: 4rem; }
  @media (min-width: 1600px) {
    .container {
      width: 157rem; } }
  @media (max-width: 1600px) {
    .container {
      width: 117rem; } }
  @media (max-width: 1280px) {
    .container {
      width: 97rem; } }
  @media (max-width: 1080px) {
    .container {
      width: 74rem; } }
  @media (max-width: 820px) {
    .container {
      width: 60rem; } }
  @media (max-width: 640px) {
    .container {
      width: 46rem; } }
  @media (max-width: 480px) {
    .container {
      width: 100%; } }

/*------------------------------------*\
    Form ( use Gravity Forms )
    Style write by Gravity Form, to force use !important
   .gform_wrapper
\*------------------------------------*/
.gform_wrapper {
  margin-top: 4rem;
  margin-bottom: 4rem; }
  .gform_wrapper ::placeholder {
    color: var(--c-black); }
  .gform_wrapper .gform_validation_errors {
    margin-bottom: 2rem !important; }
  .gform_wrapper .gfield_required {
    color: var(--c-brand) !important; }
  .gform_wrapper .gfield.gfield_error {
    background-color: transparent !important;
    border-width: 0 !important;
    padding: 0 !important; }
  .gform_wrapper .gfield_error label,
  .gform_wrapper .gfield_error legend {
    color: var(--c-error) !important; }
  .gform_wrapper .gfield_error input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]),
  .gform_wrapper .gfield_error textarea,
  .gform_wrapper .gfield_error select,
  .gform_wrapper .gfield_error [aria-invalid=true] {
    border-color: var(--c-error); }
  .gform_wrapper .gfield_error div.ginput_complex.ginput_container label,
  .gform_wrapper .gfield_error ul.gfield_checkbox,
  .gform_wrapper .gfield_error ul.gfield_radio {
    border-color: var(--c-error); }
  .gform_wrapper .gfield {
    margin-top: 2rem; }
  .gform_wrapper input[type=text],
  .gform_wrapper input[type=email],
  .gform_wrapper input[type=tel],
  .gform_wrapper textarea,
  .gform_wrapper select {
    color: var(--c-black);
    background-color: var(--c-white);
    border-color: var(--c-brand);
    border-style: solid;
    border-radius: .25rem;
    border-width: .15rem;
    padding: 1rem !important;
    width: 100% !important;
    line-height: 2; }
  .gform_wrapper select {
    height: 5rem; }
  .gform_wrapper .gfield_label,
  .gform_wrapper .ginput_complex label {
    font-size: 1.8rem !important; }
  .gform_wrapper .ginput_container_consent {
    display: flex;
    align-items: center; }
    .gform_wrapper .ginput_container_consent input[type=checkbox] {
      flex-shrink: 0;
      width: 3rem !important;
      height: 3rem;
      margin-top: 0 !important;
      margin-right: 2rem;
      vertical-align: middle;
      accent-color: var(--c-brand-accent); }
    .gform_wrapper .ginput_container_consent .gfield_consent_label {
      line-height: 1.4; }
      .gform_wrapper .ginput_container_consent .gfield_consent_label a {
        color: var(--c-brand);
        border-bottom: .1rem solid currentColor; }
  .gform_wrapper .gfield_error .ginput_container_consent .gfield_consent_label {
    color: var(--c-error); }
  .gform_wrapper .gfield--type-html a {
    text-decoration: underline; }
  .gform_wrapper .gform_footer {
    margin: 4rem auto 0 !important;
    padding: 0 !important; }
  .gform_wrapper .gform_footer button,
  .gform_wrapper .gform_footer input[type=submit] {
    display: flex;
    align-items: center;
    border: none;
    padding: 2rem 4rem;
    font-size: 2rem;
    transition: 0.15s padding ease;
    color: var(--c-brand);
    border: .1rem solid currentColor;
    background-color: transparent; }
    .gform_wrapper .gform_footer button:hover, .gform_wrapper .gform_footer button:focus,
    .gform_wrapper .gform_footer input[type=submit]:hover,
    .gform_wrapper .gform_footer input[type=submit]:focus {
      color: var(--c-white);
      background-color: var(--c-brand-dark);
      border-color: var(--c-brand); }

.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  white-space: nowrap;
  height: 1px;
  width: 1px;
  overflow: hidden; }

.screen-reader-text:focus {
  background-color: #f1f1f1;
  border-radius: 3px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  color: #21759b;
  display: block;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: bold;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000;
  /* Above WP toolbar. */ }

/*------------------------------------*\
   Paginazione ( custom_pagination() --> extra  )
   .pagination
\*------------------------------------*/
.pagination {
  margin-top: 8rem;
  display: inline-flex;
  gap: 2rem;
  flex-wrap: wrap;
  justify-content: center; }
  .pagination span,
  .pagination a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4rem;
    height: 4rem;
    font-size: 2rem;
    color: var(--c-brand);
    background-color: transparent;
    border: .1rem solid currentColor; }
  .pagination .current {
    color: var(--c-white);
    background-color: var(--c-brand);
    border-color: var(--c-brand); }
  .pagination a {
    -webkit-transition: all 0.5s;
    transition: all 0.5s; }
    .pagination a:hover, .pagination a:focus-within {
      background-color: var(--c-brand-accent); }
  .pagination .first {
    margin: 0; }

/*------------------------------------*\
    Box
    .box
\*------------------------------------*/
/*------------------------------------*\
    Pagina
    .box.box--page
\*------------------------------------*/
.box--page {
  padding: 4rem; }
  .box--page .inner {
    margin: auto;
    max-width: 157rem; }
    .box--page .inner > .title {
      color: var(--c-brand);
      display: flex;
      align-items: center;
      gap: 1rem;
      font-size: 2.6rem;
      line-height: 1.2;
      margin-bottom: 4rem; }
      .box--page .inner > .title::before {
        content: '';
        display: inline-block;
        width: 6.5rem;
        height: .6rem;
        background-color: currentColor; }

/*------------------------------------*\
    Header
   #header.header
\*------------------------------------*/
.header {
  position: relative;
  z-index: 10;
  background-color: transparent;
  font-family: var(--f-title); }

/*------------------------------------*\
    Menu: Sport ( sport_menu )
   .sport_menu
\*------------------------------------*/
.sport_menu {
  height: 6rem; }
  @media (max-width: 1080px) {
    .sport_menu {
      display: none; } }
  .sport_menu .menu {
    width: 100%;
    height: 100%;
    display: flex;
    color: var(--c-white);
    background-color: var(--c-brand); }
    .sport_menu .menu .menu-item {
      display: flex;
      justify-content: center;
      align-items: center; }
      .sport_menu .menu .menu-item .link {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%; }
      .sport_menu .menu .menu-item.home {
        flex-shrink: 0;
        width: 6rem; }
        .sport_menu .menu .menu-item.home .icon {
          flex-shrink: 0;
          width: 3rem;
          height: 3rem;
          fill: currentColor; }
          .sport_menu .menu .menu-item.home .icon svg {
            display: block;
            width: 100%;
            height: 100%; }
      .sport_menu .menu .menu-item:not(.home) {
        text-align: center;
        width: 100%;
        background-color: var(--c-brand-dark);
        font-size: 1.4rem;
        line-height: 1;
        font-weight: var(--f--bold);
        text-transform: uppercase;
        border-left: 0.1rem solid var(--c-brand); }
        .sport_menu .menu .menu-item:not(.home) .link {
          width: calc(100% - .1rem);
          gap: .5rem; }
        .sport_menu .menu .menu-item:not(.home) .icon {
          flex-shrink: 0;
          width: 2.5rem;
          height: 2.5rem;
          fill: currentColor; }
          .sport_menu .menu .menu-item:not(.home) .icon svg {
            display: block;
            width: 100%;
            height: 100%; }
      .sport_menu .menu .menu-item:hover, .sport_menu .menu .menu-item:focus-within, .sport_menu .menu .menu-item.current {
        color: var(--c-brand);
        background-color: var(--c-brand-accent); }

/*------------------------------------*\
    Header content
   .header__content
\*------------------------------------*/
.header__content {
  position: relative;
  height: 20rem;
  background-color: var(--c-brand);
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(../images/header-bg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; }
  @media (max-width: 1080px) {
    .header__content {
      height: 10rem; } }
  .header__content .brand {
    width: 10rem; }
    @media (max-width: 1080px) {
      .header__content .brand {
        width: 6.5rem; } }
    .header__content .brand .link,
    .header__content .brand svg {
      display: block;
      width: 100%;
      height: 100%; }

/*------------------------------------*\
    Open Nav menu
   .nav_open
\*------------------------------------*/
.nav_open {
  position: absolute;
  top: 50%;
  right: 4rem;
  transform: translateY(-50%);
  border: none;
  padding: 0;
  color: var(--c-white);
  background-color: transparent;
  fill: currentColor;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
  .nav_open:hover, .nav_open:focus-within {
    color: var(--c-brand-accent); }
  .nav_open .icon {
    display: block;
    width: 4rem;
    height: 4rem; }
    .nav_open .icon svg {
      display: block;
      width: 100%;
      height: 100%;
      filter: drop-shadow(0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.9)); }
  .nav_open .label {
    font-family: var(--f-text);
    margin-top: 1rem;
    display: block;
    font-size: 1.4rem;
    line-height: 1;
    text-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.9); }

/*------------------------------------*\
    Main Navigation
   nav.main__nav
\*------------------------------------*/
.main__nav {
  color: var(--c-white);
  background-color: var(--c-brand);
  position: fixed;
  z-index: 99999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  will-change: transform, opacity, visibility;
  transform: translateX(-100%);
  opacity: 0;
  visibility: hidden;
  transition: transform 0.5s ease, opacity 0.5s ease, visibility 0.5s;
  /*------------------------------------*\
        Main Menu -> main_menu
        Main Navigation
        nav.main_menu
    \*------------------------------------*/ }
  .main__nav :focus {
    outline: 0.1rem dashed var(--c-brand-accent);
    outline-offset: .2rem; }
  .main__nav .content {
    padding: 4rem;
    display: flex;
    justify-content: center;
    align-items: center; }
  .main__nav .brand {
    width: 8rem;
    margin-left: 4.5rem; }
    .main__nav .brand .link,
    .main__nav .brand svg {
      display: block;
      width: 100%;
      height: 100%; }
  .main__nav .nav_close {
    order: 1;
    flex-shrink: 0;
    margin-left: auto;
    z-index: 5;
    border: none;
    padding: 0;
    color: var(--c-white);
    background-color: transparent;
    fill: currentColor;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
    .main__nav .nav_close:hover, .main__nav .nav_close:focus-within {
      color: var(--c-brand-accent); }
    .main__nav .nav_close .icon {
      display: block;
      width: 3rem;
      height: 3rem; }
      .main__nav .nav_close .icon svg {
        display: block;
        width: 100%;
        height: 100%; }
    .main__nav .nav_close .label {
      font-family: var(--f-text);
      margin-top: 1rem;
      display: block;
      font-size: 1.4rem;
      line-height: 1;
      text-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.25); }
      @media (max-width: 1080px) {
        .main__nav .nav_close .label {
          font-size: 1.6rem; } }
  .main__nav .main_menu {
    position: relative;
    z-index: 2;
    margin-right: auto;
    padding: 0 4rem 4rem; }
    .main__nav .main_menu .menu {
      display: flex;
      flex-direction: column; }
      .main__nav .main_menu .menu > .menu-item:not(:first-of-type) {
        margin-top: 2rem; }
      .main__nav .main_menu .menu > .menu-item > a {
        font-size: 1.8rem;
        line-height: 1;
        text-transform: uppercase;
        display: inline-flex;
        align-items: center; }
        .main__nav .main_menu .menu > .menu-item > a::before {
          display: inline-flex;
          width: 2.5rem;
          height: 2.5rem;
          content: '';
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          margin-right: 2rem;
          filter: invert(100%); }
      .main__nav .main_menu .menu > .menu-item.home > a::before {
        background-image: url(../images/icon-home.svg); }
      .main__nav .main_menu .menu > .menu-item.basket > a::before {
        background-image: url(../images/icon-basket.svg); }
      .main__nav .main_menu .menu > .menu-item.volley > a::before {
        background-image: url(../images/icon-volley.svg); }
      .main__nav .main_menu .menu > .menu-item.tennis > a::before {
        background-image: url(../images/icon-tennis.svg); }
      .main__nav .main_menu .menu > .menu-item.ski-nordic > a::before {
        background-image: url(../images/icon-ski-nordic.svg); }
      .main__nav .main_menu .menu > .menu-item.ski-alpine > a::before {
        background-image: url(../images/icon-ski-alpine.svg); }
      .main__nav .main_menu .menu > .menu-item.athletics > a::before {
        background-image: url(../images/icon-athletics.svg); }
      .main__nav .main_menu .menu > .menu-item.rugby > a::before {
        background-image: url(../images/icon-rugby.svg); }
      .main__nav .main_menu .menu > .menu-item.hiking > a::before {
        background-image: url(../images/icon-hiking.svg); }
      .main__nav .main_menu .menu .menu-item > a:hover,
      .main__nav .main_menu .menu .menu-item > a:focus-within,
      .main__nav .main_menu .menu .menu-item.current-menu-item > a,
      .main__nav .main_menu .menu .menu-item.current-menu-parent > a,
      .main__nav .main_menu .menu .menu-item.current-menu-ancestor > a {
        color: var(--c-brand-accent); }
      .main__nav .main_menu .menu > .menu-item.current-menu-item > a::before,
      .main__nav .main_menu .menu > .menu-item.current-menu-parent > a::before,
      .main__nav .main_menu .menu > .menu-item.current-menu-ancestor > a::before {
        filter: invert(77%) sepia(87%) saturate(916%) hue-rotate(355deg) brightness(95%) contrast(99%); }
      .main__nav .main_menu .menu .sub-menu {
        display: none;
        margin-bottom: 2rem;
        margin-left: 4rem; }
        .main__nav .main_menu .menu .sub-menu .menu-item a {
          display: inline-flex;
          margin-left: calc(2.5rem); }
      .main__nav .main_menu .menu .menu-item-has-children > a {
        align-items: center; }
        .main__nav .main_menu .menu .menu-item-has-children > a::after {
          display: inline-flex;
          margin-top: .2rem;
          margin-left: 1rem;
          content: '';
          width: 0;
          height: 0;
          border-left: .5rem solid transparent;
          border-right: .5rem solid transparent;
          border-top: .5rem solid currentColor;
          border-bottom: initial; }
      .main__nav .main_menu .menu .menu-item-has-children.open > .sub-menu {
        display: block; }
      .main__nav .main_menu .menu .menu-item-has-children.open > a::after {
        margin-top: .2rem;
        border-top: initial;
        border-bottom: .5rem solid currentColor; }
  .main__nav.open, .main__nav.toggled-on {
    transform: translateX(0%);
    opacity: 1;
    visibility: visible; }

/*------------------------------------*\
    Menu: Page ( page_menu )
   .page_menu
\*------------------------------------*/
.page_menu {
  height: 3rem; }
  @media (max-width: 1080px) {
    .page_menu {
      display: none; } }
  .page_menu .menu {
    display: flex;
    align-items: center;
    gap: 2rem;
    padding-left: 2rem;
    padding-right: 2rem;
    color: var(--c-brand);
    background-color: var(--c-brand-accent);
    height: 100%; }
    .page_menu .menu .menu-item a:focus {
      outline: 0.1rem dashed var(--c-brand);
      outline-offset: 0; }
    .page_menu .menu > .menu-item {
      position: relative;
      height: 100%;
      font-size: 1.4rem;
      line-height: 1;
      font-weight: var(--f--normal); }
      .page_menu .menu > .menu-item > a {
        display: inline-flex;
        align-items: center;
        height: 100%; }
      .page_menu .menu > .menu-item:hover > a,
      .page_menu .menu > .menu-item:focus-within > a {
        text-decoration: underline; }
      .page_menu .menu > .menu-item.current-menu-item > a,
      .page_menu .menu > .menu-item.current-menu-parent > a,
      .page_menu .menu > .menu-item.current-menu-ancestor > a,
      .page_menu .menu > .menu-item.current_page_parent > a {
        text-decoration: underline; }
    .page_menu .menu .sub-menu {
      position: absolute;
      top: 100%;
      left: 0;
      display: none;
      opacity: 0;
      color: var(--c-brand);
      background-color: var(--c-brand-accent);
      border: 0.1rem solid var(--c-brand);
      min-width: 16rem;
      padding: 1rem; }
      .page_menu .menu .sub-menu .menu-item:not(:first-of-type) {
        margin-top: 1rem; }
      .page_menu .menu .sub-menu .menu-item a {
        line-height: 1;
        display: block; }
      .page_menu .menu .sub-menu .menu-item:hover > a,
      .page_menu .menu .sub-menu .menu-item:focus-within > a {
        text-decoration: underline; }
      .page_menu .menu .sub-menu .menu-item.current-menu-item > a,
      .page_menu .menu .sub-menu .menu-item.current-menu-parent > a,
      .page_menu .menu .sub-menu .menu-item.current-menu-ancestor > a {
        text-decoration: underline; }
    .page_menu .menu > .menu-item-has-children > a {
      align-items: center;
      position: relative; }
      .page_menu .menu > .menu-item-has-children > a::after {
        display: inline-block;
        content: '';
        width: 0;
        height: 0;
        margin-left: .5rem;
        border-left: .5rem solid transparent;
        border-right: .5rem solid transparent;
        border-top: .5rem solid currentColor;
        border-bottom: initial; }
    .page_menu .menu .menu-item-has-children:hover > .sub-menu,
    .page_menu .menu .menu-item-has-children:focus > .sub-menu,
    .page_menu .menu .menu-item-has-children:focus-within > .sub-menu,
    .page_menu .menu .menu-item-has-children.open > .sub-menu {
      display: block;
      opacity: 1; }
    .page_menu .menu .menu-item-has-children:hover > a ::after,
    .page_menu .menu .menu-item-has-children.open > a::after {
      border-top: initial;
      border-bottom: .5rem solid currentColor; }

/*------------------------------------*\
    Footer
   #footer.footer
\*------------------------------------*/
.footer {
  margin-top: 4rem;
  position: relative;
  padding: 2rem;
  font-family: var(--f-title); }
  .footer .footer_instagram {
    color: var(--c-brand);
    padding: 2rem; }
    .footer .footer_instagram .title {
      margin: auto;
      max-width: 157rem;
      color: var(--c-brand);
      display: flex;
      align-items: center;
      gap: 1rem;
      font-size: 2.6rem;
      font-weight: var(--f--normal);
      line-height: 1.2;
      margin-bottom: 4rem; }
      .footer .footer_instagram .title::before {
        content: '';
        display: inline-block;
        width: 6.5rem;
        height: .6rem;
        background-color: currentColor; }
  .footer .footer_sponsor {
    color: var(--c-brand);
    background-color: var(--c-white);
    padding: 2rem; }
    .footer .footer_sponsor .title {
      margin: auto;
      max-width: 157rem;
      color: var(--c-brand);
      display: flex;
      align-items: center;
      gap: 1rem;
      font-size: 2.6rem;
      font-weight: var(--f--normal);
      line-height: 1.2;
      margin-bottom: 4rem; }
      .footer .footer_sponsor .title::before {
        content: '';
        display: inline-block;
        width: 6.5rem;
        height: .6rem;
        background-color: currentColor; }
    .footer .footer_sponsor .items {
      margin: auto;
      max-width: 157rem;
      margin-top: 4rem;
      margin-bottom: 4rem;
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;
      gap: 4rem;
      /*.slick-list {
                margin: 0 -8rem;
                @media (max-width: 1080px) { margin: 0 -6rem; }
            }
            .slick-slide {
                margin: 0 8rem;
                @media (max-width: 1080px) { margin: 0 -6rem; }
            }*/ }
      .footer .footer_sponsor .items .slick-list,
      .footer .footer_sponsor .items .slick-track,
      .footer .footer_sponsor .items .slick-slide,
      .footer .footer_sponsor .items .item {
        width: 100%;
        height: 100%; }
      .footer .footer_sponsor .items .slick-dots {
        margin: auto;
        margin-top: 0;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center; }
        .footer .footer_sponsor .items .slick-dots li {
          position: relative;
          display: inline-flex;
          align-items: center;
          width: 2.4rem;
          height: 2rem;
          cursor: pointer; }
          .footer .footer_sponsor .items .slick-dots li:not(:first-of-type) {
            margin-left: 2rem; }
          .footer .footer_sponsor .items .slick-dots li::before {
            content: '';
            display: block;
            width: 100%;
            height: .6rem;
            background-color: var(--c-grey-normal); }
          .footer .footer_sponsor .items .slick-dots li.slick-active::before {
            background-color: var(--c-brand); }
        .footer .footer_sponsor .items .slick-dots button {
          display: none; }
      .footer .footer_sponsor .items .item .image {
        display: block;
        width: 20rem;
        height: 10rem;
        margin: auto; }
        .footer .footer_sponsor .items .item .image .img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: contain; }
  .footer .footer__getintouch {
    color: var(--c-white);
    background-color: var(--c-grey-dark);
    padding: 4rem 2rem; }
    .footer .footer__getintouch .inner {
      margin: auto;
      max-width: 157rem;
      display: grid;
      grid-gap: 4rem;
      grid-template-columns: 1fr 1fr; }
      @media (max-width: 1080px) {
        .footer .footer__getintouch .inner {
          grid-template-columns: repeat(1, 1fr); } }
    .footer .footer__getintouch .cta .title {
      margin: auto;
      color: var(--c-white);
      display: flex;
      align-items: center;
      gap: 1rem;
      font-size: 2.4rem;
      line-height: 1.2; }
      .footer .footer__getintouch .cta .title::before {
        content: '';
        display: inline-block;
        width: 6.5rem;
        height: .6rem;
        background-color: currentColor; }
    .footer .footer__getintouch .cta .title + .title2 {
      margin-top: 1rem; }
    .footer .footer__getintouch .cta .title2 {
      margin-left: calc(6.5rem + 1rem);
      color: var(--c-brand-accent);
      font-size: 1.8rem;
      line-height: 1.2; }
    .footer .footer__getintouch .cta .link {
      display: inline-block;
      margin-top: 2rem;
      margin-left: calc(6.5rem + 1rem);
      color: var(--c-brand-accent);
      background-color: var(--c-black);
      padding: 1.5rem 3rem;
      text-transform: uppercase;
      font-size: 1.4rem;
      line-height: 1.1; }
      .footer .footer__getintouch .cta .link:hover, .footer .footer__getintouch .cta .link:focus-within {
        color: var(--c-black);
        background-color: var(--c-brand-accent); }
    .footer .footer__getintouch .social {
      border-left: 0.1rem solid var(--c-grey-light);
      display: flex;
      flex-direction: column;
      align-items: center; }
      @media (max-width: 1080px) {
        .footer .footer__getintouch .social {
          border-left: none;
          border-top: 0.1rem solid var(--c-grey-light);
          padding-top: 2rem; } }
      .footer .footer__getintouch .social .title {
        color: var(--c-brand-accent);
        font-size: 2.2rem; }
      .footer .footer__getintouch .social .items {
        margin: auto;
        margin-top: 1rem;
        display: flex;
        flex-wrap: wrap;
        gap: 2rem 1rem; }
        @media (max-width: 820px) {
          .footer .footer__getintouch .social .items {
            justify-content: center; } }
      .footer .footer__getintouch .social .link {
        color: var(--c-white);
        display: block;
        width: 5rem;
        height: 5rem; }
        .footer .footer__getintouch .social .link svg {
          display: block;
          width: 100%;
          height: 100%;
          fill: currentColor; }
        .footer .footer__getintouch .social .link:hover, .footer .footer__getintouch .social .link:focus-within {
          color: var(--c-brand-accent); }
  .footer .footer__content {
    padding: 2rem 0;
    display: flex;
    gap: 4rem; }
    @media (max-width: 640px) {
      .footer .footer__content {
        flex-direction: column; } }
    .footer .footer__content .brand {
      align-self: flex-start;
      width: 10rem; }
      .footer .footer__content .brand .link,
      .footer .footer__content .brand svg {
        display: block;
        width: 100%;
        height: 100%; }
    .footer .footer__content .footer__info .title {
      font-size: 2.6rem;
      line-height: 1.2; }
    .footer .footer__content .footer__info .info {
      margin-top: 2rem; }
    .footer .footer__content .footer__info .label {
      display: flex;
      align-items: center; }
      .footer .footer__content .footer__info .label::before {
        flex-shrink: 0;
        align-self: flex-start;
        content: '';
        display: block;
        width: 2.5rem;
        height: 2.5rem;
        margin-top: .25rem;
        margin-right: 2rem;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat; }
    .footer .footer__content .footer__info .map {
      margin-top: 1rem;
      display: flex;
      align-items: center;
      margin-left: 5rem; }
      .footer .footer__content .footer__info .map::before {
        content: '';
        display: block;
        width: 2rem;
        height: 2rem;
        margin-right: 1rem;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat; }
    .footer .footer__content .footer__info .info--phone .label::before {
      background-image: url(../images/icon-phone.svg); }
    .footer .footer__content .footer__info .info--email .label::before {
      background-image: url(../images/icon-mail.svg); }
    .footer .footer__content .footer__info .info--address .label::before {
      background-image: url(../images/icon-address.svg); }
    .footer .footer__content .footer__info .map::before {
      background-image: url(../images/icon-external.svg); }
  .footer .footer__legal {
    padding: 2rem;
    margin: 0 -2rem -2rem -2rem;
    font-size: 1.4rem;
    color: var(--c-white);
    background-color: var(--c-brand-dark);
    display: flex;
    flex-wrap: wrap;
    gap: 4rem; }
    @media (max-width: 820px) {
      .footer .footer__legal {
        flex-direction: column; } }
    .footer .footer__legal .footer__policy {
      margin-left: auto; }
      @media (max-width: 820px) {
        .footer .footer__legal .footer__policy {
          margin-left: initial; } }
      .footer .footer__legal .footer__policy .items {
        display: flex;
        justify-content: center;
        gap: 2rem; }
        @media (max-width: 820px) {
          .footer .footer__legal .footer__policy .items {
            flex-direction: column; } }
        .footer .footer__legal .footer__policy .items .item {
          font-size: 1.4rem;
          text-transform: uppercase;
          display: flex;
          align-items: center; }
          .footer .footer__legal .footer__policy .items .item::before {
            content: '';
            display: block;
            width: .1rem;
            height: 1.6rem;
            background-color: var(--c-grey-light);
            margin-right: 2rem; }
          .footer .footer__legal .footer__policy .items .item:first-of-type::before {
            content: none; }
            @media (max-width: 820px) {
              .footer .footer__legal .footer__policy .items .item:first-of-type::before {
                content: ''; } }
          .footer .footer__legal .footer__policy .items .item .link:hover, .footer .footer__legal .footer__policy .items .item .link:focus-within {
            color: var(--c-brand-accent); }

/*------------------------------------*\
    Page
    page.php
    .page-template-default
\*------------------------------------*/
.page-template-default .box--page .content {
  margin-left: calc(6.5rem + 1rem); }
  @media (max-width: 820px) {
    .page-template-default .box--page .content {
      margin-left: initial; } }
  .page-template-default .box--page .content .title {
    font-size: 3.2rem;
    line-height: 1.25; }
  .page-template-default .box--page .content .image .link {
    display: block;
    width: 100%;
    height: 100%; }
  .page-template-default .box--page .content .image .img {
    display: block; }
  .page-template-default .box--page .content .title + .text-image {
    margin-top: 2rem; }
  .page-template-default .box--page .content .text-image {
    display: flex;
    gap: 4rem; }
    @media (max-width: 1080px) {
      .page-template-default .box--page .content .text-image {
        flex-direction: column; } }
    .page-template-default .box--page .content .text-image .image {
      flex-shrink: 0;
      width: 50%;
      max-width: 70rem;
      max-height: 40rem; }
      @media (max-width: 1080px) {
        .page-template-default .box--page .content .text-image .image {
          max-width: none;
          width: 100%;
          height: 40rem; } }
      .page-template-default .box--page .content .text-image .image .img {
        width: 100%;
        height: 100%;
        object-fit: cover; }
  .page-template-default .box--page .content .text-image + .text2 {
    margin-top: 4rem; }
  .page-template-default .box--page .content .text2 + .image2 {
    margin-top: 4rem; }
  .page-template-default .box--page .content .image2 .img {
    width: 100%;
    height: auto;
    object-fit: contain; }
  .page-template-default .box--page .content .image + .text3 {
    margin-top: 4rem; }

/*------------------------------------*\
    Post Type: Articoli ( post )
    General
\*------------------------------------*/
.blog .aside .sticky,
.archive.date .aside .sticky,
.archive.category .aside .sticky,
.single-post .aside .sticky {
  position: sticky;
  top: 4rem; }

.blog .aside .widget,
.archive.date .aside .widget,
.archive.category .aside .widget,
.single-post .aside .widget {
  padding: 2rem 4rem;
  background-color: var(--c-white); }
  .blog .aside .widget:not(:first-of-type),
  .archive.date .aside .widget:not(:first-of-type),
  .archive.category .aside .widget:not(:first-of-type),
  .single-post .aside .widget:not(:first-of-type) {
    margin-top: 4rem; }
  .blog .aside .widget .title,
  .archive.date .aside .widget .title,
  .archive.category .aside .widget .title,
  .single-post .aside .widget .title {
    margin: -2rem -4rem 2rem -4rem;
    color: var(--c-white);
    background-color: var(--c-brand-panel);
    padding: 2rem;
    font-size: 2.6rem;
    line-height: 1; }

.blog .aside .news,
.archive.date .aside .news,
.archive.category .aside .news,
.single-post .aside .news {
  margin-top: 4rem; }
  .blog .aside .news > .title,
  .archive.date .aside .news > .title,
  .archive.category .aside .news > .title,
  .single-post .aside .news > .title {
    color: var(--c-white);
    background-color: var(--c-brand-panel);
    padding: 2rem;
    font-size: 2.6rem;
    line-height: 1; }
  .blog .aside .news .items,
  .archive.date .aside .news .items,
  .archive.category .aside .news .items,
  .single-post .aside .news .items {
    padding: 4rem;
    background-color: var(--c-white); }
    .blog .aside .news .items .item:not(:first-of-type),
    .archive.date .aside .news .items .item:not(:first-of-type),
    .archive.category .aside .news .items .item:not(:first-of-type),
    .single-post .aside .news .items .item:not(:first-of-type) {
      margin-top: 2rem; }
    .blog .aside .news .items .item .meta,
    .archive.date .aside .news .items .item .meta,
    .archive.category .aside .news .items .item .meta,
    .single-post .aside .news .items .item .meta {
      font-size: 1.2rem;
      line-height: 1.5; }
    .blog .aside .news .items .item .title,
    .archive.date .aside .news .items .item .title,
    .archive.category .aside .news .items .item .title,
    .single-post .aside .news .items .item .title {
      font-size: 2rem;
      font-weight: var(--f--bold);
      line-height: 1.2; }
    .blog .aside .news .items .item .link,
    .archive.date .aside .news .items .item .link,
    .archive.category .aside .news .items .item .link,
    .single-post .aside .news .items .item .link {
      font-size: 1.4rem;
      color: var(--c-brand);
      border-bottom: .1rem solid currentColor;
      text-transform: lowercase; }
      .blog .aside .news .items .item .link:hover, .blog .aside .news .items .item .link:focus-within,
      .archive.date .aside .news .items .item .link:hover,
      .archive.date .aside .news .items .item .link:focus-within,
      .archive.category .aside .news .items .item .link:hover,
      .archive.category .aside .news .items .item .link:focus-within,
      .single-post .aside .news .items .item .link:hover,
      .single-post .aside .news .items .item .link:focus-within {
        background-color: var(--c-brand-accent); }

/*------------------------------------*\
    Post Type: Articoli ( post )
    index.php
\*------------------------------------*/
.blog,
.archive.date,
.archive.category {
  /*------------------------------------*\
        Posts
        .box.box--posts
    \*------------------------------------*/ }
  .blog .box--posts,
  .archive.date .box--posts,
  .archive.category .box--posts {
    padding: 4rem; }
    .blog .box--posts .inner,
    .archive.date .box--posts .inner,
    .archive.category .box--posts .inner {
      margin: auto;
      max-width: 157rem;
      display: grid;
      grid-gap: 4rem;
      grid-template-columns: minmax(0, 2fr) 1fr; }
      @media (max-width: 1080px) {
        .blog .box--posts .inner,
        .archive.date .box--posts .inner,
        .archive.category .box--posts .inner {
          grid-template-columns: repeat(1, minmax(0, 1fr)); } }
    .blog .box--posts .content > .title,
    .archive.date .box--posts .content > .title,
    .archive.category .box--posts .content > .title {
      color: var(--c-brand);
      display: flex;
      align-items: center;
      gap: 1rem;
      font-size: 2.6rem;
      font-weight: var(--f--normal);
      line-height: 1.2;
      margin-bottom: 4rem; }
      .blog .box--posts .content > .title::before,
      .archive.date .box--posts .content > .title::before,
      .archive.category .box--posts .content > .title::before {
        content: '';
        display: inline-block;
        width: 6.5rem;
        height: .6rem;
        background-color: currentColor; }
    .blog .box--posts .content .items .item:not(:first-of-type),
    .archive.date .box--posts .content .items .item:not(:first-of-type),
    .archive.category .box--posts .content .items .item:not(:first-of-type) {
      margin-top: 4rem; }
    .blog .box--posts .content .items .item .meta,
    .archive.date .box--posts .content .items .item .meta,
    .archive.category .box--posts .content .items .item .meta {
      font-size: 1.2rem;
      line-height: 1.5; }
    .blog .box--posts .content .items .item .title,
    .archive.date .box--posts .content .items .item .title,
    .archive.category .box--posts .content .items .item .title {
      font-size: 2rem;
      font-weight: var(--f--bold);
      line-height: 1.2; }
    .blog .box--posts .content .items .item .text,
    .archive.date .box--posts .content .items .item .text,
    .archive.category .box--posts .content .items .item .text {
      margin-top: 1rem;
      font-size: 1.4rem;
      line-height: 1.5; }
    .blog .box--posts .content .items .item .link,
    .archive.date .box--posts .content .items .item .link,
    .archive.category .box--posts .content .items .item .link {
      font-size: 1.4rem;
      color: var(--c-brand);
      border-bottom: .1rem solid currentColor;
      text-transform: lowercase; }
      .blog .box--posts .content .items .item .link:hover, .blog .box--posts .content .items .item .link:focus-within,
      .archive.date .box--posts .content .items .item .link:hover,
      .archive.date .box--posts .content .items .item .link:focus-within,
      .archive.category .box--posts .content .items .item .link:hover,
      .archive.category .box--posts .content .items .item .link:focus-within {
        background-color: var(--c-brand-accent); }

/*------------------------------------*\
    Post Type: Articoli ( post )
    single.php
\*------------------------------------*/
.single-post {
  /*------------------------------------*\
        Post
        .box.box--post
    \*------------------------------------*/ }
  .single-post .box--post {
    padding: 4rem; }
    .single-post .box--post .inner {
      margin: auto;
      max-width: 157rem;
      display: grid;
      grid-gap: 4rem;
      grid-template-columns: minmax(0, 2fr) 1fr; }
      @media (max-width: 1080px) {
        .single-post .box--post .inner {
          grid-template-columns: repeat(1, minmax(0, 1fr)); } }
    .single-post .box--post .content > .title {
      color: var(--c-brand);
      display: flex;
      align-items: center;
      gap: 1rem;
      font-size: 2.6rem;
      font-weight: var(--f--normal);
      line-height: 1.2;
      margin-bottom: 4rem; }
      .single-post .box--post .content > .title::before {
        content: '';
        display: inline-block;
        width: 6.5rem;
        height: .6rem;
        background-color: currentColor; }
    .single-post .box--post .content .meta {
      font-size: 1.2rem;
      line-height: 1.5;
      margin-left: calc(6.5rem + 1rem);
      margin-bottom: .5rem; }
  .single-post .text--wysiwyg .gform_wrapper {
    margin-top: 4rem;
    margin-bottom: 4rem; }

/*------------------------------------*\
    Custom Post Type: Sport ( basket, … )
    General
\*------------------------------------*/
/*------------------------------------*\
    Sport News
    .box.box--sport-news
\*------------------------------------*/
.box--sport-news {
  padding: 4rem; }
  .box--sport-news .inner {
    margin: auto;
    max-width: 157rem; }
  .box--sport-news .title-intro {
    display: flex;
    align-items: center;
    margin-bottom: 2rem; }
    .box--sport-news .title-intro .badge {
      display: inline-flex;
      align-items: center;
      min-width: 19.5rem;
      height: 6.5rem;
      color: var(--c-brand-accent);
      background-color: var(--c-brand);
      fill: currentColor;
      padding-left: 2rem;
      padding-right: 2rem; }
      .box--sport-news .title-intro .badge .icon {
        width: 3rem;
        height: 3rem; }
        .box--sport-news .title-intro .badge .icon svg {
          display: block;
          width: 100%;
          height: 100%; }
      .box--sport-news .title-intro .badge .label {
        margin-left: 1rem;
        color: var(--c-white);
        font-weight: var(--f--bold);
        text-transform: uppercase; }
    .box--sport-news .title-intro .title {
      margin-left: 2rem;
      color: var(--c-brand);
      font-size: 2.6rem;
      font-weight: var(--f--normal);
      line-height: 1.1; }
  .box--sport-news .items {
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: 2fr 1fr; }
    @media (max-width: 1080px) {
      .box--sport-news .items {
        grid-template-columns: repeat(1, 1fr); } }
    .box--sport-news .items .item {
      position: relative;
      height: 50rem;
      display: flex;
      flex-direction: column; }
      @media (max-width: 1080px) {
        .box--sport-news .items .item {
          height: 30rem; } }
      .box--sport-news .items .item .link {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        position: relative; }
        .box--sport-news .items .item .link::before {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border: .4rem solid transparent;
          transition: 0.25s border-color ease; }
        .box--sport-news .items .item .link:hover::before, .box--sport-news .items .item .link:focus-within::before {
          border-color: var(--c-brand-accent); }
      .box--sport-news .items .item .img {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: -1;
        background-color: var(--c-grey-normal); }
      .box--sport-news .items .item .item-content {
        margin-top: auto;
        padding: 4rem;
        color: var(--c-white);
        background: transparent linear-gradient(0deg, black 0%, rgba(103, 103, 103, 0.6) 80%, rgba(255, 255, 255, 0) 100%) 0% 0% no-repeat padding-box; }
      .box--sport-news .items .item .meta {
        font-size: 1.2rem;
        line-height: 1.5; }
      .box--sport-news .items .item .title {
        font-size: 2rem;
        font-weight: var(--f--bold);
        line-height: 1.2; }
      .box--sport-news .items .item .text {
        margin-top: 1rem;
        font-size: 1.4rem;
        line-height: 1.5; }

/*------------------------------------*\
    Sport
    .box.box--sport
\*------------------------------------*/
.box--sport {
  padding: 4rem; }
  .box--sport .inner {
    margin: auto;
    max-width: 157rem;
    display: grid;
    grid-gap: 4rem;
    grid-template-columns: minmax(0, 2fr) 1fr; }
    @media (max-width: 1080px) {
      .box--sport .inner {
        grid-template-columns: repeat(1, minmax(0, 1fr)); } }
  .box--sport .content .badge {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 6.5rem;
    height: 6.5rem;
    color: var(--c-brand-accent);
    background-color: var(--c-brand);
    fill: currentColor;
    margin-bottom: 2rem; }
    .box--sport .content .badge .icon {
      width: 3rem;
      height: 3rem; }
      .box--sport .content .badge .icon svg {
        display: block;
        width: 100%;
        height: 100%; }
  .box--sport .content > .title {
    color: var(--c-brand);
    display: flex;
    align-items: center;
    gap: 1rem;
    font-size: 2.6rem;
    font-weight: var(--f--normal);
    line-height: 1.2;
    margin-bottom: 4rem; }
    .box--sport .content > .title::before {
      content: '';
      display: inline-block;
      width: 6.5rem;
      height: .6rem;
      background-color: currentColor; }
  .box--sport .content .items .item:not(:first-of-type) {
    margin-top: 8rem; }
  .box--sport .content .items .item .title {
    font-size: 3.2rem;
    line-height: 1.1;
    margin-bottom: 2rem; }
  .box--sport .content .items .item .text {
    font-size: 1.8rem;
    line-height: 1.5; }
  .box--sport .content .items .item .link {
    margin-top: 2rem;
    display: inline-block;
    color: var(--c-brand);
    padding: 1rem 2rem;
    border: .1rem solid currentColor;
    text-decoration: underline;
    text-transform: lowercase;
    transition: 0.25s background-color ease; }
    .box--sport .content .items .item .link:hover, .box--sport .content .items .item .link:focus-within {
      background-color: var(--c-brand-accent); }
  .box--sport .aside .sticky {
    position: sticky;
    top: 4rem; }
  .box--sport .aside .news:first-of-type {
    margin-top: 8rem; }
  .box--sport .aside .news:not(:first-of-type) {
    margin-top: 4rem; }
  .box--sport .aside .news > .title {
    color: var(--c-white);
    background-color: var(--c-brand-panel);
    padding: 2rem;
    font-size: 2.6rem;
    line-height: 1; }
  .box--sport .aside .news .items {
    padding: 4rem;
    background-color: var(--c-white); }
    .box--sport .aside .news .items .item:not(:first-of-type) {
      margin-top: 2rem; }
    .box--sport .aside .news .items .item .meta {
      font-size: 1.2rem;
      line-height: 1.5; }
    .box--sport .aside .news .items .item .title {
      font-size: 2rem;
      font-weight: var(--f--bold);
      line-height: 1.2; }
    .box--sport .aside .news .items .item .text {
      margin-top: 1rem;
      font-size: 1.4rem;
      line-height: 1.5; }
    .box--sport .aside .news .items .item .link {
      font-size: 1.4rem;
      color: var(--c-brand);
      border-bottom: .1rem solid currentColor;
      text-transform: lowercase; }
      .box--sport .aside .news .items .item .link:hover, .box--sport .aside .news .items .item .link:focus-within {
        background-color: var(--c-brand-accent); }

/*------------------------------------*\
    Custom Post Type: Sport ( basket, … )
    archive-basket.php
\*------------------------------------*/
/*------------------------------------*\
    Custom Post Type: Sport ( basket, … )
    single-basket.php
\*------------------------------------*/
/*------------------------------------*\
    Page Template: Homepage
    homepage.php
    .page-template-homepage
\*------------------------------------*/
.page-template-homepage .box--page .content {
  margin-left: calc(6.5rem + 1rem); }
  @media (max-width: 820px) {
    .page-template-homepage .box--page .content {
      margin-left: initial; } }
  .page-template-homepage .box--page .content .title {
    font-size: 3.2rem;
    line-height: 1.25; }

.page-template-homepage .box--sport-news .inner > .title {
  color: var(--c-brand);
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: 2.6rem;
  line-height: 1.2;
  margin-bottom: 2rem; }
  .page-template-homepage .box--sport-news .inner > .title::before {
    content: '';
    display: inline-block;
    width: 6.5rem;
    height: .6rem;
    background-color: currentColor; }

.page-template-homepage .box--sport-news .items {
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(12, 1fr); }
  @media (max-width: 820px) {
    .page-template-homepage .box--sport-news .items {
      grid-template-columns: repeat(1, 1fr); } }
  .page-template-homepage .box--sport-news .items .item:nth-of-type(1) {
    grid-column: span 8; }
    @media (max-width: 1080px) {
      .page-template-homepage .box--sport-news .items .item:nth-of-type(1) {
        grid-column: span 6;
        height: 40rem; } }
    @media (max-width: 820px) {
      .page-template-homepage .box--sport-news .items .item:nth-of-type(1) {
        height: 30rem; } }
  .page-template-homepage .box--sport-news .items .item:nth-of-type(2) {
    grid-column: span 4; }
    @media (max-width: 1080px) {
      .page-template-homepage .box--sport-news .items .item:nth-of-type(2) {
        grid-column: span 6;
        height: 40rem; } }
    @media (max-width: 820px) {
      .page-template-homepage .box--sport-news .items .item:nth-of-type(2) {
        height: 30rem; } }
  .page-template-homepage .box--sport-news .items .item:nth-of-type(n+3) {
    grid-column: span 3;
    height: 40rem; }
    @media (max-width: 1080px) {
      .page-template-homepage .box--sport-news .items .item:nth-of-type(n+3) {
        grid-column: span 6; } }
    @media (max-width: 820px) {
      .page-template-homepage .box--sport-news .items .item:nth-of-type(n+3) {
        height: 30rem; } }

.page-template-homepage .box--news-general {
  padding: 4rem; }
  .page-template-homepage .box--news-general .inner {
    margin: auto;
    max-width: 157rem;
    background-color: var(--c-white); }
    .page-template-homepage .box--news-general .inner > .title {
      color: var(--c-white);
      background-color: var(--c-brand-panel);
      padding: 2rem;
      font-size: 2.4rem;
      line-height: 1; }
    .page-template-homepage .box--news-general .inner .news-all {
      margin-top: 2rem;
      text-align: center;
      padding: 2rem; }
      .page-template-homepage .box--news-general .inner .news-all .link {
        display: inline-block;
        margin: auto;
        font-size: 1.4rem;
        color: var(--c-brand);
        border-bottom: .1rem solid currentColor;
        text-transform: lowercase; }
        .page-template-homepage .box--news-general .inner .news-all .link:hover, .page-template-homepage .box--news-general .inner .news-all .link:focus-within {
          background-color: var(--c-brand-accent); }
  .page-template-homepage .box--news-general .items {
    padding: 2rem;
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: repeat(3, 1fr); }
    @media (max-width: 1080px) {
      .page-template-homepage .box--news-general .items {
        grid-template-columns: repeat(2, 1fr); } }
    @media (max-width: 820px) {
      .page-template-homepage .box--news-general .items {
        grid-template-columns: repeat(1, 1fr); } }
    .page-template-homepage .box--news-general .items .item .meta {
      font-size: 1.2rem;
      line-height: 1.5; }
    .page-template-homepage .box--news-general .items .item .title {
      font-size: 2rem;
      font-weight: var(--f--bold);
      line-height: 1.2; }
    .page-template-homepage .box--news-general .items .item .text {
      margin-top: 1rem;
      font-size: 1.4rem;
      line-height: 1.5; }
    .page-template-homepage .box--news-general .items .item .link {
      font-size: 1.4rem;
      color: var(--c-brand);
      border-bottom: .1rem solid currentColor;
      text-transform: lowercase; }
      .page-template-homepage .box--news-general .items .item .link:hover, .page-template-homepage .box--news-general .items .item .link:focus-within {
        background-color: var(--c-brand-accent); }

.page-template-homepage .box--news-sport {
  padding: 4rem; }
  .page-template-homepage .box--news-sport .inner {
    margin: auto;
    max-width: 157rem;
    background-color: var(--c-white); }
    .page-template-homepage .box--news-sport .inner > .title {
      color: var(--c-white);
      background-color: var(--c-brand-panel);
      padding: 2rem;
      font-size: 2.4rem;
      line-height: 1; }
  .page-template-homepage .box--news-sport .content {
    padding: 2rem;
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: repeat(3, 1fr); }
    @media (max-width: 1080px) {
      .page-template-homepage .box--news-sport .content {
        grid-template-columns: repeat(2, 1fr); } }
    @media (max-width: 820px) {
      .page-template-homepage .box--news-sport .content {
        grid-template-columns: repeat(1, 1fr); } }

.page-template-homepage .news--sport {
  padding: 4rem;
  border: 0.1rem solid var(--c-grey-normal); }
  .page-template-homepage .news--sport > .title {
    font-size: 1.8rem;
    font-weight: var(--f-bold);
    text-transform: uppercase;
    margin-bottom: 2rem; }
  .page-template-homepage .news--sport .items .item:not(:first-of-type) {
    margin-top: 2rem; }
  .page-template-homepage .news--sport .items .item .meta {
    font-size: 1.2rem;
    line-height: 1.5; }
  .page-template-homepage .news--sport .items .item .title {
    font-size: 2rem;
    font-weight: var(--f--bold);
    line-height: 1.2; }
  .page-template-homepage .news--sport .items .item .text {
    margin-top: 1rem;
    font-size: 1.4rem;
    line-height: 1.5; }
  .page-template-homepage .news--sport .items .item .link {
    font-size: 1.4rem;
    color: var(--c-brand);
    border-bottom: .1rem solid currentColor;
    text-transform: lowercase; }
    .page-template-homepage .news--sport .items .item .link:hover, .page-template-homepage .news--sport .items .item .link:focus-within {
      background-color: var(--c-brand-accent); }

/*------------------------------------*\
    Page Template: Sponsor
    sponsor.php
    .page-template-sponsor
\*------------------------------------*/
.page-template-sponsor .box--page .content .title {
  font-size: 3.2rem;
  line-height: 1.25; }

.page-template-sponsor .box--page .sponsor {
  margin-top: 4rem;
  background-color: var(--c-white); }
  .page-template-sponsor .box--page .sponsor > .title {
    color: var(--c-white);
    background-color: var(--c-brand-panel);
    padding: 2rem;
    font-size: 2.4rem;
    font-weight: var(--f-normal);
    line-height: 1.2; }
  .page-template-sponsor .box--page .sponsor .items {
    padding: 4rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 8rem 4rem; }
    @media (max-width: 640px) {
      .page-template-sponsor .box--page .sponsor .items {
        padding: 2rem;
        gap: 4rem; } }
    .page-template-sponsor .box--page .sponsor .items .item {
      width: 20rem;
      text-align: center; }
      .page-template-sponsor .box--page .sponsor .items .item .image {
        display: block;
        width: 100%;
        height: 10rem;
        margin: auto; }
        .page-template-sponsor .box--page .sponsor .items .item .image .img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: contain; }
      .page-template-sponsor .box--page .sponsor .items .item > .title {
        margin-top: 2rem;
        font-weight: var(--f-bold); }
      .page-template-sponsor .box--page .sponsor .items .item > .link {
        font-size: 1.4rem; }
      .page-template-sponsor .box--page .sponsor .items .item .info {
        margin-top: 2rem; }
        .page-template-sponsor .box--page .sponsor .items .item .info .info-title {
          margin: auto;
          border-radius: 50%;
          width: 3rem;
          height: 3rem;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          color: var(--c-white);
          background-color: var(--c-brand); }
          .page-template-sponsor .box--page .sponsor .items .item .info .info-title:hover, .page-template-sponsor .box--page .sponsor .items .item .info .info-title:focus-within {
            background-color: var(--c-brand-dark); }
          .page-template-sponsor .box--page .sponsor .items .item .info .info-title::before {
            content: '+'; }
        .page-template-sponsor .box--page .sponsor .items .item .info .info-content {
          margin-top: 2rem;
          font-size: 1.4rem; }
          .page-template-sponsor .box--page .sponsor .items .item .info .info-content .text + .link {
            margin-top: 1rem; }
          .page-template-sponsor .box--page .sponsor .items .item .info .info-content .link {
            text-decoration: underline; }
        .page-template-sponsor .box--page .sponsor .items .item .info[open] .info-title {
          content: '-'; }

/*------------------------------------*\
    Page Template: Form / Moduli
    form.php
    .page-template-form
\*------------------------------------*/
.page-template-form .box--page .content {
  margin-left: calc(6.5rem + 1rem); }
  @media (max-width: 820px) {
    .page-template-form .box--page .content {
      margin-left: initial; } }
  .page-template-form .box--page .content .title {
    font-size: 3.2rem;
    line-height: 1.25; }
  .page-template-form .box--page .content .gform_wrapper {
    max-width: 80rem; }

/*------------------------------------*\
    Page Template: Policy
    policy.php
    .page-template-policy
\*------------------------------------*/
