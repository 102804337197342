/*------------------------------------*\
    Header
   #header.header
\*------------------------------------*/

.header {

    position: relative;
    z-index: 10;
    background-color: transparent;

    font-family: var(--f-title);

}// .header





/*------------------------------------*\
    Menu: Sport ( sport_menu )
   .sport_menu
\*------------------------------------*/

.sport_menu {

    height: $h-header-sport;

    @media #{$media-m} { display: none; }

    .menu {

        width: 100%;
        height: 100%;
        display: flex;
        color: var(--c-white);
        background-color: var(--c-brand);

        .menu-item {

            display: flex;
            justify-content: center;
            align-items: center;

            .link {

                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;

            }// .link

            &.home {

                flex-shrink: 0;
                width: 6rem;

                .icon {

                    flex-shrink: 0;
                    width: 3rem;
                    height: 3rem;
                    fill: currentColor;

                    svg {
                        display: block;
                        width: 100%;
                        height: 100%;
                    }

                }// .icon

            }// &.home

            &:not(.home) {

                text-align: center;
                width: 100%;
                background-color: var(--c-brand-dark);

                font-size: 1.4rem;
                line-height: 1;
                font-weight: var(--f--bold);
                text-transform: uppercase;

                border-left: .1rem solid var(--c-brand);

                .link {

                    width: calc(100% - .1rem);
                    gap: .5rem;

                }// .link

                .icon {

                    flex-shrink: 0;
                    width: 2.5rem;
                    height: 2.5rem;
                    fill: currentColor;

                    svg {
                        display: block;
                        width: 100%;
                        height: 100%;
                    }

                }// .icon

            }// &:not(.home)

            &:hover,
            &:focus-within,
            &.current {

                color: var(--c-brand);
                background-color: var(--c-brand-accent);

            }// &.current

        }// .menu-item

    }// .menu

}// .sport_menu





/*------------------------------------*\
    Header content
   .header__content
\*------------------------------------*/

.header__content {

    position: relative;
    height: $h-header-content;
    background-color: var(--c-brand);

    display: flex;
    justify-content: center;
    align-items: center;

    background-image: url(../images/header-bg.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    @media #{$media-m} {
        height: 10rem;
    }

    .brand {

        width: 10rem;

        @media #{$media-m} { width: 6.5rem; }

        .link,
        svg {
            display: block;
            width: 100%;
            height: 100%;
        }

    }// .brand

}// .header__content





/*------------------------------------*\
    Open Nav menu
   .nav_open
\*------------------------------------*/

.nav_open {

    position: absolute;
    top: 50%;
    right: 4rem;
    transform: translateY(-50%);

    border: none;
    padding: 0;
    color: var(--c-white);
    background-color: transparent;
    fill: currentColor;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &:hover,
    &:focus-within {

        color: var(--c-brand-accent);

    }

    .icon {

        display: block;
        width: 4rem;
        height: 4rem;

        svg {
            display: block;
            width: 100%;
            height: 100%;
            filter: drop-shadow(.1rem .1rem .1rem rgba(0,0,0,.9));
        }

    }// .icon

    .label {

        font-family: var(--f-text);
        margin-top: 1rem;
        display: block;
        font-size: 1.4rem;
        line-height: 1;
        text-shadow: .1rem .1rem .1rem rgba(0,0,0,.9);

    }// .label

}// .nav_open





/*------------------------------------*\
    Main Navigation
   nav.main__nav
\*------------------------------------*/

.main__nav {

    color: var(--c-white);
    background-color: var(--c-brand);

    position: fixed;
    z-index: 99999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;

    will-change: transform, opacity, visibility;
    transform: translateX(-100%);
    opacity: 0;
    visibility: hidden;
    transition: transform 0.5s ease, opacity 0.5s ease, visibility 0.5s;

    :focus { outline: .1rem dashed var(--c-brand-accent); outline-offset: .2rem; }


    .content {

        padding: $gutter;
        display: flex;
        justify-content: center;
        align-items: center;

    }// .content



    .brand {

        width: 8rem;
        margin-left: 4.5rem;

        .link,
        svg {
            display: block;
            width: 100%;
            height: 100%;
        }

    }// .brand



    .nav_close {

        order: 1;
        flex-shrink: 0;
        margin-left: auto;
        z-index: 5;

        border: none;
        padding: 0;
        color: var(--c-white);
        background-color: transparent;
        fill: currentColor;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &:hover,
        &:focus-within {

            color: var(--c-brand-accent);

        }

        .icon {

            display: block;
            width: 3rem;
            height: 3rem;

            svg {
                display: block;
                width: 100%;
                height: 100%;
            }

        }// .icon

        .label {

            font-family: var(--f-text);
            margin-top: 1rem;
            display: block;
            font-size: 1.4rem;
            line-height: 1;
            text-shadow: .1rem .1rem .1rem rgba(0,0,0,.25);

            @media #{$media-m} { font-size: 1.6rem; }

        }// .label

    }// nav_close



    /*------------------------------------*\
        Main Menu -> main_menu
        Main Navigation
        nav.main_menu
    \*------------------------------------*/

    .main_menu {

        position: relative;
        z-index: 2;
        margin-right: auto;

        padding: 0 $gutter $gutter;

        .menu {

            display: flex;
            flex-direction: column;

            & > .menu-item {

                &:not(:first-of-type) { margin-top: 2rem; }

                & > a {

                    font-size: 1.8rem;
                    line-height: 1;
                    text-transform: uppercase;

                    display: inline-flex;
                    align-items: center;

                    &::before {

                        display: inline-flex;
                        width: 2.5rem;
                        height: 2.5rem;
                        content: '';
                        // background-image: url(../images/stars.svg);
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: contain;
                        margin-right: 2rem;
                        // background-color: red;

                        // CSS filter generator to convert from black to target hex color
                        // https://codepen.io/sosuke/pen/Pjoqqp
                        filter: invert(100%);
                    }

                }

                &.home > a::before { background-image: url(../images/icon-home.svg); }

                // .menu-item-object-{post_type}
                &.basket > a::before { background-image: url(../images/icon-basket.svg); }
                &.volley > a::before { background-image: url(../images/icon-volley.svg); }
                &.tennis > a::before { background-image: url(../images/icon-tennis.svg); }
                &.ski-nordic > a::before { background-image: url(../images/icon-ski-nordic.svg); }
                &.ski-alpine > a::before { background-image: url(../images/icon-ski-alpine.svg); }
                &.athletics > a::before { background-image: url(../images/icon-athletics.svg); }
                &.rugby > a::before { background-image: url(../images/icon-rugby.svg); }
                &.hiking > a::before { background-image: url(../images/icon-hiking.svg); }

            }// & > .menu-item

            .menu-item {

                & > a:hover,
                & > a:focus-within,
                &.current-menu-item > a,
                &.current-menu-parent > a,
                &.current-menu-ancestor > a {
                    color: var(--c-brand-accent);
                }

                // @at-root body.single-brand #{&}.menu-item-object-brand > a { color: var(--c-brand-accent); }
                // @at-root body.single-post #{&}.menu-item-type-post_type.current_page_parent > a { color: var(--c-brand-accent); }

            }// .menu-item

            & > .menu-item.current-menu-item > a::before,
            & > .menu-item.current-menu-parent > a::before,
            & > .menu-item.current-menu-ancestor > a::before { filter: invert(77%) sepia(87%) saturate(916%) hue-rotate(355deg) brightness(95%) contrast(99%); }

            // @at-root body.single-brand #{&} > .menu-item-object-brand > a::before { filter: invert(0%); }
            // @at-root body.single-post #{&} > .menu-item-type-post_type.current_page_parent > a::before { filter: invert(0%); }

            .sub-menu {

                display: none;
                // margin-top: 1rem;
                margin-bottom: 2rem;
                margin-left: 4rem;

                .menu-item {

                    a {

                        display: inline-flex;
                        margin-left: calc(2.5rem);

                    }

                }

            }// .sub-menu

            .menu-item-has-children > a {

                align-items: center;

                &::after {

                    display: inline-flex;
                    margin-top: .2rem;
                    margin-left: 1rem;
                    content: '';
                    width: 0;
                    height: 0;

                    border-left: .5rem solid transparent;
                    border-right: .5rem solid transparent;
                    border-top: .5rem solid currentColor;
                    border-bottom: initial;

                }

            }// .menu-item-has-children > a


            // .menu-item-has-children:hover > .sub-menu,
            // .menu-item-has-children:focus > .sub-menu,
            // .menu-item-has-children:focus-within > .sub-menu,
            .menu-item-has-children.open > .sub-menu, { display: block; }

            .menu-item-has-children.open > a::after {
                margin-top: .2rem;
                border-top: initial;
                border-bottom: .5rem solid currentColor;

            }

        }// .menu

    }// .main_menu

    &.open,
    &.toggled-on {

        transform: translateX(0%);
        opacity: 1;
        visibility: visible;

    }

}// .main__nav





/*------------------------------------*\
    Menu: Page ( page_menu )
   .page_menu
\*------------------------------------*/

.page_menu {

    height: $h-header-page;

    @media #{$media-m} { display: none; }

    .menu {

        display: flex;
        align-items: center;
        gap: 2rem;
        padding-left: 2rem;
        padding-right: 2rem;

        color: var(--c-brand);
        background-color: var(--c-brand-accent);
        height: 100%;

        .menu-item a:focus { outline: .1rem dashed var(--c-brand); outline-offset: 0; }

        & > .menu-item {

            position: relative;
            height: 100%;
            font-size: 1.4rem;
            line-height: 1;
            font-weight: var(--f--normal);

            & > a {

                display: inline-flex;
                align-items: center;
                height: 100%;

            }

            &:hover > a,
            &:focus-within > a {
                text-decoration: underline;
            }

            &.current-menu-item > a,
            &.current-menu-parent > a,
            &.current-menu-ancestor > a,
            &.current_page_parent > a {
                text-decoration: underline;
            }

        }// .menu-item

        .sub-menu {

            // display: none;
            position: absolute;
            top: 100%;
            left: 0;
            display: none;
            opacity: 0;

            color: var(--c-brand);
            background-color: var(--c-brand-accent);
            border: .1rem solid var(--c-brand);

            min-width: 16rem;
            padding: 1rem;

            .menu-item {

                &:not(:first-of-type) { margin-top: 1rem; }

                a {
                    line-height: 1;
                    display: block;
                }

                &:hover > a,
                &:focus-within > a {
                    text-decoration: underline;
                }

                &.current-menu-item > a,
                &.current-menu-parent > a,
                &.current-menu-ancestor > a {
                    text-decoration: underline;
                }

            }// .menu-item

        }// .sub-menu

        & > .menu-item-has-children > a {

            align-items: center;
            position: relative;

            &::after {

                display: inline-block;
                content: '';
                width: 0;
                height: 0;
                margin-left: .5rem;

                border-left: .5rem solid transparent;
                border-right: .5rem solid transparent;
                border-top: .5rem solid currentColor;
                border-bottom: initial;

            }

        }

        .menu-item-has-children:hover > .sub-menu,
        .menu-item-has-children:focus > .sub-menu,
        .menu-item-has-children:focus-within > .sub-menu,
        .menu-item-has-children.open > .sub-menu {
            display: block;
            opacity: 1;
        }

        .menu-item-has-children:hover > a ::after,
        .menu-item-has-children.open > a::after {
            border-top: initial;
            border-bottom: .5rem solid currentColor;
            // transform: rotate(180deg) translateX(.5rem);
        }

    }// .menu

}// .page_menu
