/*------------------------------------*\
    Page Template: Homepage
    homepage.php
    .page-template-homepage
\*------------------------------------*/

.page-template-homepage {



    .box--page {

        .content {

            margin-left: calc(6.5rem + 1rem);

            @media #{$media-sm} { margin-left: initial; }

            .title {

                font-size: 3.2rem;
                line-height: 1.25;

            }// .title

            .text {}// .text

        }// .content

    }// .box--page



    .box--sport-news {

        .inner > .title {

            color: var(--c-brand);
            display: flex;
            align-items: center;
            gap: 1rem;

            font-size: 2.6rem;
            line-height: 1.2;
            margin-bottom: $gutter*.5;

            &::before {
                content: '';
                display: inline-block;
                width: 6.5rem;
                height: .6rem;
                background-color: currentColor;
            }

        }// .title

        .items {

            display: grid;
            grid-gap: $gutter*.5;
            grid-template-columns: repeat(12, 1fr);

            @media #{$media-sm} { grid-template-columns: repeat(1, 1fr); }

            .item:nth-of-type(1) {
                grid-column: span 8;
                @media #{$media-m} { grid-column: span 6; height: 40rem; }
                @media #{$media-sm} { height: 30rem; }
            }
            .item:nth-of-type(2) {
                grid-column: span 4;
                @media #{$media-m} { grid-column: span 6; height: 40rem; }
                @media #{$media-sm} { height: 30rem; }
            }
            .item:nth-of-type(n+3) {
                grid-column: span 3;
                height: 40rem;
                @media #{$media-m} { grid-column: span 6; }
                @media #{$media-sm} { height: 30rem; }
            }

        }// .items

    }// .box--sport-news



    .box--news-general {

        padding: $gutter;

        .inner {

            margin: auto;
            max-width: $w-media-u;
            background-color: var(--c-white);

            & > .title {

                color: var(--c-white);
                background-color: var(--c-brand-panel);

                padding: 2rem;

                font-size: 2.4rem;
                line-height: 1;

            }// & > .title

            .news-all {

                margin-top: $gutter*.5;
                text-align: center;
                padding: $gutter*.5;

                .link {

                    display: inline-block;
                    margin: auto;

                    font-size: 1.4rem;
                    color: var(--c-brand);
                    // text-decoration: underline;
                    border-bottom: .1rem solid currentColor;
                    text-transform: lowercase;

                    &:hover,
                    &:focus-within {
                        background-color: var(--c-brand-accent);
                    }


                }// .link

            }

        }// .inner

        .items {

            padding: $gutter*.5;

            display: grid;
            grid-gap: $gutter*.5;
            grid-template-columns: repeat(3, 1fr);

            @media #{$media-m} { grid-template-columns: repeat(2, 1fr); }
            @media #{$media-sm} { grid-template-columns: repeat(1, 1fr); }

            .item {

                .meta {

                    font-size: 1.2rem;
                    line-height: 1.5;

                }// .meta

                .title {

                    font-size: 2rem;
                    font-weight: var(--f--bold);
                    line-height: 1.2;

                }// .title

                .text {

                    // font-size: 1.4rem;
                    // line-height: 1.5;
                    margin-top: $gutter*.25;
                    font-size: 1.4rem;
                    line-height: 1.5;

                }// .text

                .link {

                    font-size: 1.4rem;
                    color: var(--c-brand);
                    // text-decoration: underline;
                    border-bottom: .1rem solid currentColor;
                    text-transform: lowercase;

                    &:hover,
                    &:focus-within {
                        background-color: var(--c-brand-accent);
                    }

                }// .link

            }// .item

        }// .items

    }// .box--news-general



    .box--news-sport {

        padding: $gutter;

        .inner {

            margin: auto;
            max-width: $w-media-u;
            background-color: var(--c-white);

            & > .title {

                color: var(--c-white);
                background-color: var(--c-brand-panel);

                padding: 2rem;

                font-size: 2.4rem;
                line-height: 1;

            }// & > .title

        }// .inner

        .content {

            padding: $gutter*.5;

            // display: flex;
            // flex-wrap: wrap;

            display: grid;
            grid-gap: $gutter*.5;
            grid-template-columns: repeat(3, 1fr);

            @media #{$media-m} { grid-template-columns: repeat(2, 1fr); }
            @media #{$media-sm} { grid-template-columns: repeat(1, 1fr); }

        }// .content

    }// .box--news

    .news--sport {

        padding: $gutter;
        border: .1rem solid var(--c-grey-normal);


        & > .title {

            font-size: 1.8rem;
            font-weight: var(--f-bold);
            text-transform: uppercase;
            margin-bottom: $gutter*.5;

        }// & > .title

        .items {

            .item {

                &:not(:first-of-type) { margin-top: $gutter*.5; }

                .meta {

                    font-size: 1.2rem;
                    line-height: 1.5;

                }// .meta

                .title {

                    font-size: 2rem;
                    font-weight: var(--f--bold);
                    line-height: 1.2;

                }// .title

                .text {

                    // font-size: 1.4rem;
                    // line-height: 1.5;
                    margin-top: $gutter*.25;
                    font-size: 1.4rem;
                    line-height: 1.5;

                }// .text

                .link {

                    font-size: 1.4rem;
                    color: var(--c-brand);
                    // text-decoration: underline;
                    border-bottom: .1rem solid currentColor;
                    text-transform: lowercase;

                    &:hover,
                    &:focus-within {
                        background-color: var(--c-brand-accent);
                    }

                }// .link

            }// .item

        }// .items

    }// .news



}// .page-template-homepage
