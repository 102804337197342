/*------------------------------------*\
    Page
    page.php
    .page-template-default
\*------------------------------------*/

.page-template-default {



    .box--page {

        .content {

            margin-left: calc(6.5rem + 1rem);

            @media #{$media-sm} { margin-left: initial; }

            .title {

                font-size: 3.2rem;
                line-height: 1.25;

            }// .title

            .text {}// .text

            .image {

                .link {

                    display: block;
                    width: 100%;
                    height: 100%;

                }// .link

                .img {

                    display: block;

                }// .img

            }// .image

            .title + .text-image { margin-top: $gutter*.5; }

            .text-image {

                display: flex;
                gap: $gutter;

                @media #{$media-m} { flex-direction: column; }

                .text {}// .text

                .image {

                    flex-shrink: 0;
                    width: 50%;
                    max-width: 70rem;
                    max-height: 40rem;

                    @media #{$media-m} {
                        max-width: none;
                        width: 100%;
                        height: 40rem;
                    }

                    .img {

                        width: 100%;
                        height: 100%;
                        object-fit: cover;

                    }// .img

                }// .image

            }// .text-image

            .text-image + .text2 { margin-top: $gutter; }

            .text2 + .image2 { margin-top: $gutter; }

            .image2 {

                .img {

                    width: 100%;
                    height: auto;
                    object-fit: contain;

                }// .img

            }// .image2

            .image + .text3 { margin-top: $gutter; }

        }// .content

    }// .box--page



}// .page-template-default
