/*------------------------------------*\
   Paginazione ( custom_pagination() --> extra  )
   .pagination
\*------------------------------------*/

.pagination {

	margin-top: 8rem;
	display: inline-flex;
	gap: 2rem;
	flex-wrap: wrap;
	justify-content: center;

	span,
    a {

		display: flex;
		justify-content: center;
		align-items: center;
		width: 4rem;
		height: 4rem;
        font-size: 2rem;

		color: var(--c-brand);
		background-color: transparent;
		border: .1rem solid currentColor;

    }

	.current {
        color: var(--c-white);
		background-color: var(--c-brand);
		border-color: var(--c-brand);
	}

	a {

		-webkit-transition: all 0.5s;
		transition: all 0.5s;

		&:hover,
		&:focus-within {
            background-color: var(--c-brand-accent);
        }

	}

	.first { margin: 0; }

	.first,
	.prev,
	.next,
	.last {}

}// .pagination
