/*------------------------------------*\
    Post Type: Articoli ( post )
    General
\*------------------------------------*/

.blog,
.archive.date,
.archive.category,
.single-post {



    .aside {

        .sticky {
            position: sticky;
            top: 4rem;
        }

        .widget {

            padding: 2rem 4rem;
            background-color: var(--c-white);

            &:not(:first-of-type) { margin-top: $gutter; }

            .title {

                margin: -2rem -4rem 2rem -4rem;
                color: var(--c-white);
                background-color: var(--c-brand-panel);

                padding: 2rem;

                font-size: 2.6rem;
                line-height: 1;

            }// .title

        }// .widget

        .news {

            margin-top: $gutter;

            & > .title {

                color: var(--c-white);
                background-color: var(--c-brand-panel);

                padding: 2rem;

                font-size: 2.6rem;
                line-height: 1;

            }// & > .title

            .items {

                padding: $gutter;
                background-color: var(--c-white);

                .item {

                    &:not(:first-of-type) { margin-top: $gutter*.5; }

                    .meta {

                        font-size: 1.2rem;
                        line-height: 1.5;

                    }// .meta

                    .title {

                        font-size: 2rem;
                        font-weight: var(--f--bold);
                        line-height: 1.2;

                    }// .title

                    .text {

                        // font-size: 1.4rem;
                        // line-height: 1.5;

                    }// .text

                    .link {

                        font-size: 1.4rem;
                        color: var(--c-brand);
                        // text-decoration: underline;
                        border-bottom: .1rem solid currentColor;
                        text-transform: lowercase;

                        &:hover,
                        &:focus-within {
                            background-color: var(--c-brand-accent);
                        }

                    }// .link

                }// .item

            }// .items

        }// .news

    }// .aside



}// .blog









/*------------------------------------*\
    Post Type: Articoli ( post )
    index.php
\*------------------------------------*/

.blog,
.archive.date,
.archive.category {



    /*------------------------------------*\
        Posts
        .box.box--posts
    \*------------------------------------*/

    .box--posts {

        padding: $gutter;




        .inner {

            margin: auto;
            max-width: $w-media-u;

            display: grid;
            grid-gap: $gutter;
            grid-template-columns: minmax(0, 2fr) 1fr;

            @media #{$media-m} { grid-template-columns: repeat( 1, minmax(0, 1fr) ); }

        }// .inner

        .content {

            & > .title {

                color: var(--c-brand);
                display: flex;
                align-items: center;
                gap: 1rem;

                font-size: 2.6rem;
                font-weight: var(--f--normal);
                line-height: 1.2;
                margin-bottom: $gutter;

                &::before {
                    content: '';
                    display: inline-block;
                    width: 6.5rem;
                    height: .6rem;
                    background-color: currentColor;
                }

            }// .title

            .items {

                .item {

                    &:not(:first-of-type) { margin-top: $gutter; }

                    .meta {

                        font-size: 1.2rem;
                        line-height: 1.5;

                    }// .meta

                    .title {

                        font-size: 2rem;
                        font-weight: var(--f--bold);
                        line-height: 1.2;

                    }// .title

                    .text {

                        margin-top: $gutter*.25;
                        font-size: 1.4rem;
                        line-height: 1.5;

                    }// .text

                    .link {

                        font-size: 1.4rem;
                        color: var(--c-brand);
                        border-bottom: .1rem solid currentColor;
                        text-transform: lowercase;

                        &:hover,
                        &:focus-within {
                            background-color: var(--c-brand-accent);
                        }

                    }// .link

                }// .item

            }// .items

        }// .content

    }// .box--post



}// .blog










/*------------------------------------*\
    Post Type: Articoli ( post )
    single.php
\*------------------------------------*/

.single-post {



    /*------------------------------------*\
        Post
        .box.box--post
    \*------------------------------------*/

    .box--post {

        padding: $gutter;


        .inner {

            margin: auto;
            max-width: $w-media-u;
            display: grid;
            grid-gap: $gutter;
            grid-template-columns: minmax(0, 2fr) 1fr;

            @media #{$media-m} { grid-template-columns: repeat( 1, minmax(0, 1fr) ); }

        }// .inner

        .content {

            // max-width: 90rem;

            & > .title {

                color: var(--c-brand);
                display: flex;
                align-items: center;
                gap: 1rem;

                font-size: 2.6rem;
                font-weight: var(--f--normal);
                line-height: 1.2;
                margin-bottom: $gutter;

                &::before {
                    content: '';
                    display: inline-block;
                    width: 6.5rem;
                    height: .6rem;
                    background-color: currentColor;
                }

            }// .title

            .meta {

                font-size: 1.2rem;
                line-height: 1.5;
                margin-left: calc(6.5rem + 1rem);
                margin-bottom: .5rem;

            }// .meta

        }// .content

    }// .box--post



    .text--wysiwyg {

        .gform_wrapper {

            margin-top: $gutter;
            margin-bottom: $gutter;

        }// .gform_wrapper

    }// .text--wysiwyg

}// .single-post
