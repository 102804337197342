/*------------------------------------*\
    Box
    .box
\*------------------------------------*/

.box {}// .box










/*------------------------------------*\
    Pagina
    .box.box--page
\*------------------------------------*/

.box--page {

    padding: $gutter;

    .inner {

        margin: auto;
        max-width: $w-media-u;

        & > .title {

            color: var(--c-brand);
            display: flex;
            align-items: center;
            gap: 1rem;

            font-size: 2.6rem;
            line-height: 1.2;
            margin-bottom: $gutter;

            &::before {
                content: '';
                display: inline-block;
                width: 6.5rem;
                height: .6rem;
                background-color: currentColor;
            }

        }// .title

    }// .inner

    .content {}// .content

}// .box--page
