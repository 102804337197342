// @function hsl($h, $s, $l) {
//     @return unquote('hsl(#{$h}, #{$s}, #{$l})');
// }
@function hsla($var, $a) {
    @return unquote('hsla(#{$var}, #{$a})');
}




// Responsive Media Query ( html-font-size = 62.5% = 1rem = 10px )
$media-uw:              "(min-width: 1921px)";      // media query > 1920
$media-u:               "(min-width: 1600px)";      // media query > 1600     // container 1570
$media-bx:              "(max-width: 1600px)";      // media query < 1600     // container 1170
$media-b:               "(max-width: 1280px)";      // media query < 1280     // container 97
$media-m:               "(max-width: 1080px)";      // media query < 1080     // container 74
$media-sm:              "(max-width: 820px)";       // media query < 820      // container 60
$media-s:               "(max-width: 640px)";       // media query < 640      // container 46
$media-sx:              "(max-width: 480px)";       // media query < 480      // container 100%
$media-sxx:             "(max-width: 360px)";       // media query < 360      // container 100%

$media-bx-min:          "(min-width: 1601px)";      // media query > 1600
$media-b-min:           "(min-width: 1281px)";      // media query > 1280
$media-m-min:           "(min-width: 1081px)";      // media query > 1040
$media-sm-min:          "(min-width: 821px)";      // media query > 820
$media-s-min:           "(min-width: 641px)";       // media query > 640

$media-motion:          "screen and (prefers-reduced-motion: no-preference)";



// Width
$w-media-uw:            190rem;
$w-media-u:             157rem;
$w-media-bx:            117rem;
$w-media-b:             97rem;
$w-media-m:             74rem;
$w-media-sm:            60rem;
$w-media-s:             46rem;
$w-media-sx:            100%;

// Height
$h-header:              30rem;
$h-header-sport:        6rem;
$h-header-content:      20rem;
$h-header-page:         3rem;



// Gutter
$gutter:                4rem;





// Colors
// Color Names -> http://chir.ag/projects/name-that-color/
/* Used colors

#035487     -> hsl(203, 96%, 27%)
#002a45     -> hsl(203, 100%, 14%)

#f2cb05     -> hsl(50, 96%, 48%)

#7b8c7e     -> hsl(131, 7%, 52%)
#9dbf21     -> hsl(73, 71%, 44%)

// greyscale

#000000     -> hsl(0, 0%, 0%)

#707070     -> hsl(0, 0%, 44%)
#cccccc     -> hsl(0, 0%, 80%)
#f2f2f2     -> hsl(0, 0%, 95%)

#ffffff     -> hsl(0, 0%, 100%)

*/

:root {

    // colors

    --c-brand:              #035487;
    --c-brand-dark:         #002a45;

    --c-brand-accent:       #f2cb05;
    --c-brand-panel:        #7b8c7e;

    --c-black:              #000;
    --c-grey-dark:          #707070;
    --c-grey-normal:        #ccc;
    --c-grey-light:         #f2f2f2;
    --c-white:              #fff;
    --c-white-hsl:          0, 0%, 100%;

    --c-bg:                 var(--c-grey-light);
    --c-fg:                 var(--c-black);

    --c-link:               var(--c-brand-accent);
    --c-link-hover:         var(--c-brand);

    --c-error:              #c00;// hsl(0, 100%, 40%)
    --c-modal:              var(--c-black);

    // height
    --h-header:             12rem;

    // gutter
    --gutter:               4rem;

    // font
    --f-title:              "Lato", sans-serif;
    --f-text:               "Open Sans", sans-serif;
    --f-normal:            normal;
    --f-bold:              bold;

}

// Fonts
// Lato:wght@400;700 -> loaded via local Font
// Open+Sans:wght@400;700 -> loaded via local Font

// $f-family-title:        "Lato", sans-serif;
// $f-family-text:         "Open Sans", sans-serif;
//
// $f-weight-400:          400;
// $f-weight-700:          700;
