/*------------------------------------*\
    Page Template: Form / Moduli
    form.php
    .page-template-form
\*------------------------------------*/

.page-template-form {



    .box--page {

        .content {

            margin-left: calc(6.5rem + 1rem);

            @media #{$media-sm} { margin-left: initial; }

            .title {

                font-size: 3.2rem;
                line-height: 1.25;

            }// .title

            .text {}// .text

            .gform_wrapper {

                max-width: 80rem;

            }// .gform_wrapper

        }// .content

    }// .box--page



}// .page-template-form
