/*------------------------------------*\
   layout
\*------------------------------------*/

html,
body {
    height: 100%;
}

body {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

    #header,
    #footer {
        flex: none;
    }

    #header {}

    #main {

        -webkit-box-flex: 1;
        -webkit-flex: 1 0 auto;
        -ms-flex: 1 0 auto;
        flex: 1 0 auto;
        position: relative;
        z-index: 1;

        // @media #{$media-uw} {
        //     width: $w-media-uw;
        //     margin: auto;
        // }

    }

    #footer {}

    .container {

        margin-right: auto;
        margin-left: auto;
        padding-right: $gutter;
        padding-left: $gutter;

        @media #{$media-u}      { width: $w-media-u; }
        @media #{$media-bx}     { width: $w-media-bx; }
        @media #{$media-b}      { width: $w-media-b; }
        @media #{$media-m}      { width: $w-media-m; }
        @media #{$media-sm}     { width: $w-media-sm; }
        @media #{$media-s}      { width: $w-media-s; }
        @media #{$media-sx}     { width: $w-media-sx; }

    }
