/*------------------------------------*\
    Custom Post Type: Sport ( basket, … )
    General
\*------------------------------------*/


/*------------------------------------*\
    Sport News
    .box.box--sport-news
\*------------------------------------*/

.box--sport-news {

    padding: $gutter;

    .inner {

        margin: auto;
        max-width: $w-media-u;

    }// .inner

    .title-intro {

        display: flex;
        align-items: center;
        margin-bottom: $gutter*.5;

        .badge {

            display: inline-flex;
            align-items: center;
            min-width: 19.5rem;
            height: 6.5rem;
            color: var(--c-brand-accent);
            background-color: var(--c-brand);
            fill: currentColor;
            padding-left: 2rem;
            padding-right: 2rem;

            .icon {

                width: 3rem;
                height: 3rem;

                svg {
                    display: block;
                    width: 100%;
                    height: 100%;
                }

            }// .icon

            .label {

                margin-left: 1rem;
                color: var(--c-white);
                font-weight: var(--f--bold);
                text-transform: uppercase;

            }// .label

        }// .badge

        .title {

            margin-left: 2rem;
            color: var(--c-brand);
            font-size: 2.6rem;
            font-weight: var(--f--normal);
            line-height: 1.1;

        }// .title

    }// .intro

    .items {

        // display: flex;

        display: grid;
        grid-gap: $gutter*.5;
        grid-template-columns: 2fr 1fr;

        @media #{$media-m} { grid-template-columns: repeat(1, 1fr); }

        .item {

            position: relative;
            height: 50rem;
            display: flex;
            flex-direction: column;

            @media #{$media-m} { height: 30rem; }

            .link {

                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                position: relative;

                &::before {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    border: .4rem solid transparent;
                    transition: 0.25s border-color ease;
                }

                &:hover,
                &:focus-within {

                    &::before { border-color: var(--c-brand-accent); }

                }

            }// .link

            .img {

                position: absolute;
                top: 0;
                left: 0;
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
                z-index: -1;
                background-color: var(--c-grey-normal);

            }// .img

            .item-content {

                margin-top: auto;
                padding: $gutter;
                color: var(--c-white);
                background: transparent linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(103,103,103,0.6) 80%, rgba(255,255,255,0) 100%) 0% 0% no-repeat padding-box;

            }// .item-content

            .meta {

                font-size: 1.2rem;
                line-height: 1.5;

            }// .meta

            .title {

                font-size: 2rem;
                font-weight: var(--f--bold);
                line-height: 1.2;

            }// .title

            .text {

                margin-top: $gutter*.25;
                font-size: 1.4rem;
                line-height: 1.5;

            }// .text

        }// .item

    }// .items

}// .box--sport-news










/*------------------------------------*\
    Sport
    .box.box--sport
\*------------------------------------*/

.box--sport {

    padding: $gutter;


    .inner {

        margin: auto;
        max-width: $w-media-u;

        display: grid;
        grid-gap: $gutter;
        grid-template-columns: minmax(0, 2fr) 1fr;

        @media #{$media-m} { grid-template-columns: repeat( 1, minmax(0, 1fr) ); }

    }// .inner

    .content {

        // max-width: 90rem;

        .badge {

            display: flex;
            justify-content: center;
            align-items: center;
            width: 6.5rem;
            height: 6.5rem;
            color: var(--c-brand-accent);
            background-color: var(--c-brand);
            fill: currentColor;
            margin-bottom: $gutter*.5;

            .icon {

                width: 3rem;
                height: 3rem;

                svg {
                    display: block;
                    width: 100%;
                    height: 100%;
                }

            }// .icon

        }// .badge

        & > .title {

            color: var(--c-brand);
            display: flex;
            align-items: center;
            gap: 1rem;

            font-size: 2.6rem;
            font-weight: var(--f--normal);
            line-height: 1.2;
            margin-bottom: $gutter;

            &::before {
                content: '';
                display: inline-block;
                width: 6.5rem;
                height: .6rem;
                background-color: currentColor;
            }

        }// .title

        .items {

            .item {

                &:not(:first-of-type) { margin-top: $gutter*2; }

                .title {

                    font-size: 3.2rem;
                    line-height: 1.1;
                    margin-bottom: $gutter*.5;

                }// .title

                .text {

                    font-size: 1.8rem;
                    line-height: 1.5;

                }// .text

                .link {

                    margin-top: $gutter*.5;
                    display: inline-block;

                    color: var(--c-brand);
                    padding: 1rem 2rem;
                    border: .1rem solid currentColor;
                    text-decoration: underline;
                    text-transform: lowercase;
                    transition: 0.25s background-color ease;

                    &:hover,
                    &:focus-within {
                        background-color: var(--c-brand-accent);
                    }

                }// .link

            }// .item

        }// .items

    }// .content

    .aside {

        .sticky {
            position: sticky;
            top: 4rem;
        }

        .news {

            &:first-of-type { margin-top: $gutter*2; }
            &:not(:first-of-type) { margin-top: $gutter; }

            & > .title {

                color: var(--c-white);
                background-color: var(--c-brand-panel);

                padding: 2rem;

                font-size: 2.6rem;
                line-height: 1;

            }// & > .title

            .items {

                padding: $gutter;
                background-color: var(--c-white);

                .item {

                    &:not(:first-of-type) { margin-top: $gutter*.5; }

                    .meta {

                        font-size: 1.2rem;
                        line-height: 1.5;

                    }// .meta

                    .title {

                        font-size: 2rem;
                        font-weight: var(--f--bold);
                        line-height: 1.2;

                    }// .title

                    .text {

                        // font-size: 1.4rem;
                        // line-height: 1.5;
                        margin-top: $gutter*.25;
                        font-size: 1.4rem;
                        line-height: 1.5;

                    }// .text

                    .link {

                        font-size: 1.4rem;
                        color: var(--c-brand);
                        // text-decoration: underline;
                        border-bottom: .1rem solid currentColor;
                        text-transform: lowercase;

                        &:hover,
                        &:focus-within {
                            background-color: var(--c-brand-accent);
                        }

                    }// .link

                }// .item

            }// .items

        }// .news

    }// .aside

}// .box--sport










/*------------------------------------*\
    Custom Post Type: Sport ( basket, … )
    archive-basket.php
\*------------------------------------*/










/*------------------------------------*\
    Custom Post Type: Sport ( basket, … )
    single-basket.php
\*------------------------------------*/

.single-basket {}// .single-basket
